
// ---- SASS
import '@/sass/reset.sass'
import '@/sass/ui.sass'
import '@/sass/grid.sass'
import '@/sass/layout.sass'
import '@/sass/plugin.sass'

import '@lottiefiles/lottie-player'
import 'font-awesome/css/font-awesome.css'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import $ from 'jquery'

import OwlCarousel from 'v-owl-carousel'
import VueQrcode from '@xkeshi/vue-qrcode'
import VueCookies from 'vue-cookies'
import VueLocalStorage from 'vue-localstorage'
import VueVideoPlayer from 'vue-video-player'
import VueYoutube from 'vue-youtube'
import 'videojs-contrib-hls'

// 載入 Library
import Common from './library/Common'
import Apis from './library/Apis'

// Import FireBase
import firebase from 'firebase/app'

// require videojs style
import 'video.js/dist/video-js.css'
// import imgKnowHow from '@/assets/images/b-know-how-img-01.jpg'

Vue.config.productionTip = false
Vue.use(VueCookies)
Vue.use(VueLocalStorage)
Vue.component(VueQrcode.name, VueQrcode)
Vue.component('carousel', OwlCarousel)
Vue.use(VueYoutube)
Vue.use(VueVideoPlayer /* {
  options: global default options,
  events: global videojs events
} */)


const configOptions = {
  apiKey: "AIzaSyBtjzm4l61fy-6_aJWOJbR6Va6ejSzKXsk",
  authDomain: "gvo-videoapp.firebaseapp.com",
  databaseURL: "https://gvo-videoapp.firebaseio.com",
  projectId: "gvo-videoapp",
  storageBucket: "gvo-videoapp.appspot.com",
  messagingSenderId: "33377823245",
  appId: "1:33377823245:web:0e5468f4fc09c35c854344",
  measurementId: "G-TSDRPRB4B7"
}

firebase.initializeApp(configOptions)

// 當每一次跳轉頁面，都把頁面 loading 打開
router.afterEach(() => {
  /* 返回到最頂上 */
  $('*').animate({ scrollTop: 0 }, 'slow');
})

new Vue({
  router,
  render: h => h(App),
  data: {
    // == ==
    common: false,
    apis: false,
    // == 系統參數 ==
    auth_token: false,
    user: {
      id: false,
      image: false,
      name: false,
      userID: false,
    },
    // == ==
    siteUri: process.env.VUE_APP_SITE_URL,
    // == ==
    nav_meta: '',
    hide_page_status: false,
    // == Token ==
    token: '',
    devId: '',
    // == 系統狀態 ==
    system_ready: false,
    padding_check: false,
    article_ready: false,
    //-- 警告視窗控制
    isAlert: false,
    //-- 影片視窗控制
    isVideo: false,
    video_id: '',
    //-- cookie bar 控制
    isCookie: false,
    isCookie_animate: false,
    //-- loading 控制
    isLoading: false,
    // == 行動號碼驗證 ==
    cellphoneStep: false,
    // == 國碼 ==
    country_codes: [
      '+1', '+61', '+64', '+44', '+84', '+86', '+886', '+852', '+853', '+81', '+82', '+60',
    ],
    country_code: '+886',
    //- 功能選單
    arrHeaderNav: [
      {
        title: '首頁',
        meta: 'home',
        status: true,
        type: 1
      },
      {
        title: '團班課表',
        meta: 'timetable',
        status: false,
        type: 1
      },
      // {
      //   title: '體驗課程',
      //   meta: 'teachers',
      //   status: false,
      //   type: 1
      // },
      {
        title: '教師登入',
        meta: 'https://edu1n1.com.tw/login/teacher',
        status: false,
        type: 2
      },
      // {
      //   title: '教師應聘',
      //   meta: 'apply',
      //   status: false
      // },
    ],
    //- 課程相關資料
    total_course: [
      { id: "MP", name: "會話班" },
      { id: "LP", name: "證照班" },
      // { id: "GM", name: "文法專班" },
      // { id: "VB", name: "字彙加強班" },
      // { id: "RW", name: "閱讀寫作專班" },
      // { id: "YS", name: "在校生班" },
      // { id: "CM", name: "商用語言班" },
      // { id: "SS", name: "觀光會話班" },
      // { id: "DM", name: "體驗課程" },
      { id: "PC", name: "專業課程班" },
      { id: "CD", name: "兒少班" },
      // { id: "PS", name: "個教班" },
      { id: "QT", name: "快訓課程班" },
      { id: "MS", name: "中學班" },
    ],
    total_langes: [
      { id: "EN", name: "美語" },
      { id: "JP", name: "日語" },
      { id: "KO", name: "韓語" }
    ]
  },
  mounted () {
    // 每次 router 更新，開都啟 loading
    this.$router.beforeEach((to, from, next) => {
      this.isLoading = true
      setTimeout(() => {
        next()
      }, 300)
    })
    // 每次 router 更新，都滾到頂端
    this.$router.afterEach(() => {
      $('html, body').scrollTop(0)
    })
  },
  async created () {
    // 先開載入中
    this.useLoading()

    // 處理基本工具
    this.common = new Common(this)
    this.apis = new Apis(this)

    // 獲取系統資訊
    await this.apis.getSystemInfo()

    setTimeout(() => {
      this.openCookie()
    }, 1500);
  },
  watch: {
    // 監測到變更語言
    language_status (_lang) {
      if (_lang) {
        console.log('--> 監測到語言被變更: ', _lang)
        this.setData('use_language', _lang)
        this.getArticles()
      }
    },
  },
  methods: {
    // 開啟 Loading 狀態
    useLoading () {
      this.isLoading = true
    },
    closeLoading () {
      this.isLoading = false
    },
    // 補0
    paddingLeft (str, lenght) {
      if (str.length >= lenght) { return str } else { return this.paddingLeft('0' + str, lenght) }
    },
    // ####################################
    // 畫面操作類
    // ####################################
    //- 至主畫面
    goHome () {
      this.$router.push({ name: 'home' })
      this.$root.nav_meta = 'home'
      this.hide_page_status = false
      this.goTop()
    },
    goPage (_page, _type = 1) {
      // 針對內部頁面
      if (_type === 1) {
        this.$router.push({ name: _page })
        if (_page === 'home' || _page === 'teachers' || _page === 'apply' || _page === 'timetable') {
          this.$root.nav_meta = _page
        } else {
          this.$root.nav_meta = ''
        }
      }

      // 針對外部頁面
      else if (_type === 2) {
        document.location.href = _page
      }
    },
    //- 打開 Video
    openVideo (_id) {
      this.isVideo = true
      this.video_id = _id
    },
    closeVideo () {
      this.isVideo = false
      this.video_id = ''
    },
    //- 打開 cookie bar
    openCookie () {
      this.isCookie = true
      this.isCookie_animate = true
    },
    //- 關閉 cookie bar
    closeCookie () {
      this.isCookie_animate = false
      setTimeout(() => {
        this.isCookie = false
      }, 300);
    },
    //- 使用 alert
    useAlert (_type, _title = '', _text = '', _buttonTxt = '') {
      this.isAlert = true
      this.alert_type = _type
      this.alert = {
        title: _title,
        text: _text,
        button: _buttonTxt
      }
    },
    // 關閉 Alert
    closeAlert () {
      this.isAlert = false
    },
    // 返回最頂端
    goTop () {
      $('html, body').animate({
        scrollTop: 0
      }, 600)
    },
    // 確認是否已經登入
    confirmIsLogin () {
      console.log('--> 開始驗證是否已經登入...')
      // 若是已經登入，就前往學員後台
      if (this.user.id) {
        console.log('--> 導向學員後台...')
        this.goPage("/student/main", 2)
      }
    },
    // ####################################
    // Firebase
    // ####################################
    // 請求發送驗證簡訊
    sendPhoneNumberCode (_phoneNumber, _success) {
      var vm = this
      console.log('--> 呼叫 FireBase Phone Auth...')
      var appVerifier = window.recaptchaVerifier
      firebase.auth().signInWithPhoneNumber(_phoneNumber, appVerifier)
        .then(function (confirmationResult) {
          // 獲取驗證憑據
          window.confirmationResult = confirmationResult
          vm.useAlert(1, "簡訊驗證", '驗證簡訊已經發送', "瞭解")
          // 處理驗證成功的方法
          if (typeof _success === 'function') {
            _success(_phoneNumber)
          }
        }).catch(function (error) {
          vm.useAlert(1, "簡訊驗證", error, "瞭解")
        })
    },
    // 請求驗證簡訊驗證碼
    confirmPhoneNumberCode (_phoneNumber, _code, _success) {
      var vm = this
      // eslint-disable-next-line no-undef
      confirmationResult.confirm(_code).then(function (result) {
        var user = result.user
        console.log('--> user: ', user)
        // vm.useAlert(1, "簡訊驗證", '簡訊驗證成功', "瞭解")
        // 處理驗證成功的方法
        if (typeof _success === 'function') {
          _success(_phoneNumber)
        }
      }).catch(function (error) {
        vm.useAlert(1, "簡訊驗證", error, "瞭解")
      })
    }
  }
}).$mount('#app');
