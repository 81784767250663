<template lang="pug">
//- 首頁
.home-index.page-index
  homeHeader
  homeBanner
  homeEdu
  homeVideo
  homeFooter
</template>
<script>
// import $ from 'jquery'
import homeHeader from '@/components/header'
import homeBanner from '@/components/home/home-banner'
import homeEdu from '@/components/home/home-edu'
import homeVideo from '@/components/home/home-video'
import homeFooter from '@/components/footer'


export default {
  name: 'Index',
  components: {
    homeHeader,
    homeBanner,
    homeEdu,
    homeVideo,
    homeFooter,
  },
  data () {
    return {
    };
  },
  async created () {
    this.$root.closeLoading()

  },
  destroyed () {
    this.$root.nav_meta = ''
  },
  mounted () {
    this.$root.nav_meta = 'home'
  },
  computed: {
  },
  methods: {

  }
}
</script>
<style lang="sass">
</style>
