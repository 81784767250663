<template lang="pug">
//- 團班課懷
.live-index.page-index
  Header
  LiveVideo
  Footer
</template>
<script>
// import $ from 'jquery'
import Header from '@/components/header'
import Footer from '@/components/footer'
import LiveVideo from '@/components/live/video'

export default {
  name: 'timetableIndex',
  components: {
    Header,
    Footer,
    LiveVideo
  },
  data () {
    return {
    };
  },
  async created () {
    this.$root.closeLoading()
  },
  mounted () {
  },
  computed: {
  },
  methods: {

  }
}
</script>
<style lang="sass">
</style>
