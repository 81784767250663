<template lang="pug">
.privacy-main
  .container-fluid-sm
    img.banner(src="@/assets/image/privacy-img.png")
    .paper-main
      .paper-normal-text 本隱私政策最後更新時間為 2020年 1 月 1 日。

      .paper-group
        .paper-title 隱私權保護政策
        .paper-normal-text
          span 親愛的學員，您個人的隱私權，地球村學習網絕對尊重並予以保護。為了讓您瞭解在使用地球村學習網所提供各種學習服務時，地球村學習網是如何蒐集、應用及保護學員提供的個人資訊，請您詳細閱讀地球村學習網的隱私權保護政策。這份文件將告訴學員 ，地球村學習網蒐集的資料、運用方式，以及本網的隱私權保護政策。本政策將視情況變更，請學員定期查詢之。

      .paper-group
        .paper-title
          span 一、隱私權保護政策適用範圍
        .paper-normal-text.paper-indent-level1
          span A. 本隱私權保護政策內容，包括【地球村學習網】如何處理學員使用本網服務時收集到的身份識別資料，也包括【地球村學習網】如何處理本網合作廠商與【地球村學習網】合作時分享的任何身份識別資料。
        .paper-normal-text.paper-indent-level1
          span B. 本隱私權保護政策不適用於【地球村學習網】以外的公司，也不適用於非【地球村學習網】所僱用或管理的人員。

      .paper-group
        .paper-title
          span 二、資料之蒐集及使用方式
        .paper-normal-text.paper-indent-level1
          span A. 地球村學習網需要學員的個人基本資料會請學員提供姓名、身份證字號、電話、電子郵件網址及住址等，我們需要上述資料以便快速而方便的為學員提供服務，並不斷提升地球村學習網服務系統；以便能提供學員更好的服務。
        .paper-normal-text.paper-indent-level1
          span B. 除了學員主動登錄地球村學習網， 提供的的個人識別資料之外，學員也可能在地球村學習網中的教學平台等主動提供其它學員個人資料如電子郵件， 姓名等。這種形式的資料提供，不在地球村學習網保護政策的範圍之內。
        .paper-normal-text.paper-indent-level1
          span C. 地球村學習網可能會利用學員個人識別資料或其他個人資料，來分析或製作報告之用 ，並僅止於市場分析或學術用途；除非當事人同意，否則所有資料僅供地球村學習網資料分析之用，地球村學習網不會將資訊用做其他用途。
        .paper-normal-text.paper-indent-level1
          span D. 但在以下的情況下，地球村學習網仍有權公開學員任何內容、記錄或電子通訊等資料：
        .paper-normal-text.paper-indent-level2
          span 1.依法配合司法檢調機關執行法律上或公務之需求
        .paper-normal-text.paper-indent-level2
          span 2.保護地球村學習網及其它學員及公眾之權利、材產或個人安全。
        .paper-normal-text.paper-indent-level2
          span 3.因學員之行為違反法規命令或本網學員規章之規定時。
        .paper-normal-text.paper-indent-level2
          span 4.地球村學習網如發現有不法情事發生時，將主動提供學員相關資料供警調單位處理。

      .paper-group
        .paper-title
          span 三、資料之保護
        .paper-normal-text.paper-indent-level1
          span A. 地球村學習網會將學員的個人資料儲存在嚴密防護的資料儲存系統中，並有這當保護措施防止未經授權人員之接觸。地球村學習網之工作團隊了解學員資料的保密是本團隊的基本責任，如有洩漏、轉售或與其他機構進行非法交換等情事，將受相關法律及公司內部規定嚴厲之處分。
        .paper-normal-text.paper-indent-level1
          span B. 為保障學員的隱私及安全，學員在地球村學習網上的個人資料會用密碼保護，學員可以隨時利用學員個人的帳號密碼變更或更新個人檔案中的資料，請妥善保管學員個人的帳號及密碼之機密與安全。使用完地球村學習網教學服務後請務必登出帳護。並不定期的更換學員個人密碼。

      .paper-group
        .paper-title
          span 四、線上信用卡付款安全機制
        .paper-normal-text.paper-indent-level1
          span A.地球村學習網由SSL（Secure Sockets Layer）「全球安全認證網站」安全加密機制保護傳輸的個人資料（如信用卡號、帳號、密碼等），所有資料皆在保密狀態下，學員可安心使用信用卡於本網交易購物。避免學員的個人資料檔案及其他相關記錄在未經當事人授權的狀況下被盜取。經登入後網頁持續未使用時，經過一段時間本網會自動將該學員帳號登出，以保護學員個人資料使用之安全。

      .paper-group
        .paper-title
          span 五、Cookies
        .paper-normal-text.paper-indent-level1
          span A. Cookies 是伺服端為了區別使用者的不同喜好，經由瀏覽器寫入使用者硬碟的一些間短資訊，Cookie 並不含任何資料足使他人透過電話、電子郵件與學員聯酪。學員可在學員個人的網站瀏覽器上設定功能以獲知何時 Cookies 被記錄或避免 Cookies 的設置。可以在IE的「Internet選項」的「安全性」中選擇修改學員瀏覽器對Cookies的接受程度，包括接受所有cookies、設定cookies時得到通知、拒絕所有cookies等三種。

      .paper-group
        .paper-title
          span 六、隱私權保護政策修訂
        .paper-normal-text.paper-indent-level1
          span A. 隨著市場環境的改變地球村學習網將會不時修訂網站政策。當本網在使用個人資料的規定上作出重大修改時，本網會在地球村學習網網站上張貼告示，通知本網學員相關事項。本網學員如果對於地球村學習網隱私權保護政策、或與個人資料有關之相關事項有任何疑問時，可以利用電子郵件或利用線上客服和地球村學習網聯酪。
</template>
<script>
// import $ from 'jquery'
export default {
  name: 'privacyMain',
  components: {

  },
  props: {},
  data () {
    return {

    };
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}
</script>
