<template lang="pug">
//- 404-view
.notfound-view
  //- img.top-img(src="@/assets/image/404-top.png")
  //- img.btm-img(src="@/assets/image/404-btm.png")
  .container-fluid-sp
    .row.align-items-center
      .col-lg-4.col-md-12.text-align-center
        //- lg
        .not-found-group.text-align-left.d-lg-block.d-none
          .notfound-title OOPS!
          .notfound-text 找不到您要查找的頁面
          button.button.btn-default.w-100.notfound-btn(@click="$root.goHome()")
            span 返回首頁
            //- img.button-backimg(src="@/assets/image/btn-next@3x.png")

        //- md
        .not-found-group.text-align-left.d-block.d-lg-none.w-70
          .notfound-title OOPS!
          .notfound-text 找不到您要查找的頁面
          button.button.btn-default.w-100.notfound-btn(@click="$root.goHome()")
            span 返回首頁
            //- img.button-backimg(src="@/assets/image/btn-next@3x.png")
        .clearfix

      .col-lg-8.col-md-12.text-aling-left
        img.big-img.ml-0(src="@/assets/image/404-big.png")
</template>
<script>
// import $ from 'jquery'

export default {
  name: '404',
  components: {

  },
  data () {
    return {

    };
  },
  mounted () {
  },
  computed: {
  },
  created () {

  },
  methods: {

  }
}
</script>
<style lang="sass">
</style>
