<template lang="pug">
//- 帳密登入
.way-1
  //- 帳號
  .form-group
    .form-group-title 帳號
    input.form-group-input(
      type="text",
      placeholder="請輸入您的帳號",
      v-model="loginData.account"
    )

  //- 密碼
  .form-group
    .form-group-title 密碼
    input.form-group-input(
      type="password",
      placeholder="請輸入您的密碼",
      v-model="loginData.password"
    )

  //- 按鈕
  .form-group
    .form-group-button.button(@click="goLogin")
      span 登入

  //- 按鈕
  .form-group
    .form-group-button-1.button(@click="changeWay(2)")
      span 手機號碼登入

  //- 分隔線
  //- .form-group-divider-text
    .divider-line
    .divider-text 使用其他方式登入

  //- 按鈕
  //- .form-group
    .form-group-button-2.button(@click="changeWay(3)")
      span 透過行動條碼登入
</template>
<script>
import md5 from 'js-md5'
export default {
  name: '',
  components: {},
  props: [],
  data () {
    return {
      //- way-1
      loginData: {
        selectAcc: 1,
        account: '',
        password: ''
      }
    };
  },
  methods: {
    changeWay (_way) {
      this.$emit('changeWay', _way);
    },
    // 處理帳密登入相關操作
    goLogin: function () {
      // 判斷是否為空
      if (!this.loginData.account) {
        this.showAlert('您必須填入帳戶名稱喔!')
        return false
      }
      if (!this.loginData.password) {
        this.showAlert('您必須填入帳戶密碼喔!')
        return false
      }
      console.log(this.loginData)
      // 處理 MD5 加密
      this.loginData.md5pwd = md5(this.loginData.password)
      // 輸出結果
      console.log('pwd: ' + this.loginData.password)
      console.log('hash: ' + this.loginData.md5pwd)
      console.log('selectAcc: ' + this.loginData.selectAcc)
      // 執行登入操作
      this.$root.apis.usePlatformByPassword(this.loginData, this.processLoginSuccess, this.processLoginError)
    },
    async processLoginSuccess (_response) {
      if (_response.body.error_code !== 0) {
        return this.processLoginError(_response)
      }
      // 處理登入後的資訊
      await this.$root.apis.getSystemInfo()
      // 如果未綁定電話號碼
      if (!this.$root.user.phone) {
        return this.$root.goPage('phone-binding')
      }
      // 處理登入後的資訊
      await this.$root.confirmIsLogin()
    },
    async processLoginError (_error) {
      await this.$root.useAlert(1, "登入發生問題", _error.body.message, "瞭解")
    }
  }
}
</script>
