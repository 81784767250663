<template lang="pug">
//- 電話綁定
.phone-binding-index.page-index
  Header
  BindPhone
  Footer
</template>
<script>
import Header from '@/components/header'
import Footer from '@/components/footer'
import BindPhone from '@/components/bind-phone'

export default {
  name: 'phoneBinding',
  components: {
    Header,
    Footer,
    BindPhone
  },
  data () {
    return {

    };
  },
  async created () {
    this.$root.closeLoading()
  },
  mounted () {
  },
  computed: {
  },
  methods: {
  }
}
</script>
<style lang="sass">
</style>
