<template lang="pug">
transition(name="fade")
  .video-view.video-wrapper
    //- 大視窗
    .video-main-lg
      //- .d-lg-block.d-md-block.d-sm-block.d-none
      img.video-main-close.cursor-pointer(
        src="@/assets/image/close.png",
        @click="$root.closeVideo()"
      )
      //- 影片區
      .video-out-view
        //- img.video-main-img(src="@/assets/image/video-1.png")
        youtube.video-main-img(
          :video-id="videoId",
          :player-vars="playerVars",
          @playing="playing"
        )
</template>
<script>
export default {
  name: 'VideoWrap',
  props: {
  },
  components: {
  },
  computed: {

  },
  created () {
    this.videoId = this.$root.video_id
  },
  data () {
    return {
      videoId: '',
      playerVars: {
        autoplay: 1
      }
    };
  },
  methods: {

  }
}
</script>
