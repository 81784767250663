<template lang="pug">
//- 團班課懷
.timetable-index.page-index
  Header
  TimetableMain
  Footer
</template>
<script>
// import $ from 'jquery'
import Header from '@/components/header'
import Footer from '@/components/footer'
import TimetableMain from '@/components/timetable/timetable-main'


export default {
  name: 'timetableIndex',
  components: {
    Header,
    Footer,
    TimetableMain
  },
  data () {
    return {
    };
  },
  async created () {
    this.$root.closeLoading()

  },
  destroyed () {
    this.$root.nav_meta = ''
  },
  mounted () {
    this.$root.nav_meta = 'timetable'
  },
  computed: {
  },
  methods: {

  }
}
</script>
<style lang="sass">
</style>
