<template lang="pug">
.timetable-filter.row
  //- 時段篩選
  //- .time-filter.col-5
    .filter-title 時段
    .filter-option-box.row
      button.filter-option-item.btn-default.col-auto.flex-grow-0.flex-shrink-0(
        v-bind:class="time.click_status ? 'on_active' : 'off_active'",
        v-for="(time, index) in time_option_data",
        @click="changeTimeFilterIn(index)"
      ) {{ time.text }}
  //- 語言篩選
  .lan-filter.col-5
    .filter-title 語言
    .filter-option-box.row
      button.filter-option-item.btn-default.col-auto.flex-grow-0.flex-shrink-0(
        v-bind:class="lan.id === set_lang ? 'on_active' : 'off_active'",
        v-for="(lan, index) in lan_option_data",
        @click="changeLanFilterIn(lan.id)"
      ) {{ lan.name }}
  //- 班別篩選
  .course-filter.col-12
    .filter-title 班別
    .filter-option-box.row
      button.filter-option-item.btn-default.col-auto.flex-grow-0.flex-shrink-0(
        v-bind:class="course.id === set_course ? 'on_active' : 'off_active'",
        v-for="(course, index) in course_option_data",
        @click="changeCourseFilterIn(course.id)"
      ) {{ course.name }}
</template>
<script>
export default {
  name: 'timetableFilter',
  components: {},
  props: ['set_time', 'set_lang', 'set_course', 'time_option_data', 'lan_option_data', 'course_option_data'],
  data () {
    return {

    };
  },
  methods: {
    changeTimeFilterIn (_index) {
      this.$emit('changeTimeFilterOut', _index);
    },
    changeLanFilterIn (_index) {
      this.$emit('changeLanFilterOut', _index);
    },
    changeCourseFilterIn (_index) {
      this.$emit('changeCourseFilterOut', _index);
    }
  }
}
</script>
