<template lang="pug">
//- 學生登入
.student-login-index.page-index
  Header
  LoginMain(from="student")
  Footer
</template>
<script>
import Header from '@/components/header'
import Footer from '@/components/footer'
import LoginMain from '@/components/login/login-main'

export default {
  name: 'studetnLoginIndex',
  components: {
    Header,
    Footer,
    LoginMain
  },
  data () {
    return {

    };
  },
  async created () {
    this.$root.closeLoading()
  },
  mounted () {
  },
  computed: {
  },
  methods: {
  }
}
</script>
<style lang="sass">
</style>
