<template lang="pug">
.timetable-date
  //- .year-title 2021年
  .date-box.row
    .col-4.text-align-left.padding-none
      //- 回到今天按鈕
      .today-btn.cursor-pointer 今天
    //- .col-4.row.padding-none
      //- 向左 按鈕
      img.col-auto.flex-grow-0.flex-shrink-0.date-img.cursor-pointer(
        src="@/assets/image/ic-arrow-left.png"
      )

      //- 日期
      .date-text.col-auto.flex-grow-0.flex-shrink-0 6月21日 － 6月27日

      //- 向右 按鈕
      img.col-auto.flex-grow-0.flex-shrink-0.date-img.cursor-pointer(
        src="@/assets/image/ic-arrow-right.png"
      )
    .col-4.padding-none
</template>
<script>
export default {
  name: 'timetableDate',
  components: {},
  props: [],
  data () {
    return {
    };
  },
  methods: {

  }
}
</script>
