<template lang="pug">
//- 教師登入
.teacher-login-index.page-index
  Header
  LoginMain(from="teacher")
  Footer
</template>
<script>
// import $ from 'jquery'
import Header from '@/components/header'
import Footer from '@/components/footer'
import LoginMain from '@/components/login/login-main'

export default {
  name: 'teacherLoginIndex',
  components: {
    Header,
    Footer,
    LoginMain
  },
  data () {
    return {
    };
  },
  async created () {
    this.$root.closeLoading()
  },
  mounted () {
  },
  computed: {
  },
  methods: {

  }
}
</script>
<style lang="sass">
</style>
