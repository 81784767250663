<template lang="pug">
//- 教師應聘
.apply-index.page-index
  Header
  ApplyMain
  Footer
</template>
<script>
// import $ from 'jquery'
import Header from '@/components/header'
import Footer from '@/components/footer'
import ApplyMain from '@/components/apply/apply-main'

export default {
  name: 'applyIndex',
  components: {
    Header,
    Footer,
    ApplyMain
  },
  data () {
    return {
    };
  },
  async created () {
    this.$root.closeLoading()

  },
  destroyed () {
    this.$root.nav_meta = ''
  },
  mounted () {
    this.$root.nav_meta = 'apply'
  },
  computed: {
  },
  methods: {

  }
}
</script>
<style lang="sass">
</style>
