var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-top"},[(_vm.page === 'teachers' || _vm.page === 'apply' || _vm.page === 'rule' || _vm.page === 'privacy' || _vm.page === 'timetable' || _vm.page === 'reading-pen')?_c('img',{staticClass:"top-bg",attrs:{"src":require("@/assets/image/page-bg.png")}}):_vm._e(),_c('div',{staticClass:"container-fluid"},[(_vm.page === 'login')?_c('img',{staticClass:"top-bg1",attrs:{"src":require("@/assets/image/page-bg1.png")}}):_vm._e()]),_c('div',{staticClass:"d-lg-block d-md-block d-sm-block d-none"},[(_vm.page === 'teachers')?_c('div',{staticClass:"top-box"},[_vm._m(0),_vm._m(1)]):_vm._e(),(_vm.page === 'reading-pen')?_c('div',{staticClass:"top-box"},[_vm._m(2),_vm._m(3)]):_vm._e(),(_vm.page === 'apply')?_c('div',{staticClass:"top-box"},[_vm._m(4),_vm._m(5)]):_vm._e(),(_vm.page === 'rule')?_c('div',{staticClass:"top-box"},[_vm._m(6),_vm._m(7)]):_vm._e(),(_vm.page === 'privacy')?_c('div',{staticClass:"top-box"},[_vm._m(8),_vm._m(9)]):_vm._e()]),_c('div',{staticClass:"d-lg-none d-md-none d-sm-none d-block"},[(_vm.page === 'teachers')?_c('div',{staticClass:"top-box"},[_vm._m(10),_vm._m(11),_vm._m(12)]):_vm._e(),(_vm.page === 'reading-pen')?_c('div',{staticClass:"top-box"},[_vm._m(13),_vm._m(14)]):_vm._e(),(_vm.page === 'apply')?_c('div',{staticClass:"top-box"},[_vm._m(15),_vm._m(16)]):_vm._e(),(_vm.page === 'rule')?_c('div',{staticClass:"top-box"},[_vm._m(17),_vm._m(18)]):_vm._e(),(_vm.page === 'privacy')?_c('div',{staticClass:"top-box"},[_vm._m(19),_vm._m(20)]):_vm._e()]),(_vm.page === 'timetable')?_c('div',{staticClass:"top-box"},[_vm._m(21),_vm._m(22)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-title title-lg"},[_c('span',[_vm._v("投資自己 掌握未來")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-text text-lg"},[_c('span',[_vm._v("外師學有專精、政府合格外師、優良專業中師、可自選適合教師")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-title title-lg"},[_c('span',[_vm._v("朗讀筆")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-text text-lg"},[_c('span',[_vm._v("有聲教材")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-title title-lg"},[_c('span',[_vm._v("數位老師應聘資料")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-text text-lg"},[_c('span',[_vm._v("成為一位教師")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-title title-lg"},[_c('span',[_vm._v("條款及細則")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-text text-lg"},[_c('span',[_vm._v("首頁 /")]),_c('span',{staticClass:"span-000"},[_vm._v("條款及細則")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-title title-lg"},[_c('span',[_vm._v("隱私權政策")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-text text-lg"},[_c('span',[_vm._v("首頁 /")]),_c('span',{staticClass:"span-000"},[_vm._v("隱私權政策")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-title title-sm"},[_c('span',[_vm._v("面向初學者，從零到滿分")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-text text-sm"},[_c('span',[_vm._v("外師學有專精、政府合格外師、")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-text text-sm"},[_c('span',[_vm._v("優良專業中師、可自選適合教師")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-title title-sm"},[_c('span',[_vm._v("朗讀筆")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-text text-sm"},[_c('span',[_vm._v("有聲教材")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-title title-sm"},[_c('span',[_vm._v("數位老師應聘資料")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-text text-sm"},[_c('span',[_vm._v("成為一位教師")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-title title-sm"},[_c('span',[_vm._v("條款及細則")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-text text-sm"},[_c('span',[_vm._v("首頁 /")]),_c('span',{staticClass:"span-000"},[_vm._v("條款及細則")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-title title-sm"},[_c('span',[_vm._v("隱私權政策")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-text text-sm"},[_c('span',[_vm._v("首頁 /")]),_c('span',{staticClass:"span-000"},[_vm._v("隱私權政策")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-title title-lg"},[_c('span',[_vm._v("團班課表")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-text text-lg"},[_c('span',[_vm._v("週週更新精進語言")])])
}]

export { render, staticRenderFns }