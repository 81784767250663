export default {
  type: 'zh-tw',
  form: {
    apply_title: '申請成為教師',
    name: '姓名',
    name_pls: '請輸入您的姓名',
    country: '您的地區(多選)',
    country_pls: '請選擇您的地區',
    sex: '性別',
    man: '男性',
    female: '女性',
    age: '年齡',
    age_pls: '請選擇您的年齡',
    email: 'Email',
    email_pls: '請輸入您的信箱',
    n_phone: '聯絡電話(市話)',
    n_phone_pls: '請輸入您的聯絡市話',
    phone: '手機號碼',
    phone_pls: '請輸入您的手機號碼',
    teach_lan: '可教學的語言(多選)',
    teach_lan_pls: '請選擇您的語言',
    delete: '刪除',
    meet_time: '方便面試時間',
    meet_time_pls: '請簡述您可方便的面試時間',
    resume: '個人履歷/自傳',
    resume_pls: '請簡述您的個人履歷/自傳',

    sign_title: '建立帳號',
    password: '密碼',
    password_pls: '請輸入您的密碼',
    sec_password: '再次確認密碼',
    sec_password_pls: '請輸入您的再次確認密碼',
    start_apply: '開始應徵'
  },
}
