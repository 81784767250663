<template lang="pug">
.reading-pen-info
  .info-imgbox
    .info-big-img
      img(src="@/assets/image/reading-pen.png")
    .info-small-img
      .sm-img-item.click-cursor
        img(src="@/assets/image/reading-pen.png")
  .info-introduce
    .info-introduce-title 地球村朗讀筆
    .info-introduce-text 
      | 可點朗讀書不會或難唸、難背的單字、句子重複點選學習「想學哪句；就點哪句」
      br 
      | 課前預習：練習單字、句型發音
      br 
      | 課中學習：朗讀書寫題目的標準答案
      br 
      | 課後複習：複習單字、句型、文章朗讀
      br 
      | 365天24小時，系統式學習，打造您全方位外語實力。
    //- .info-introduce-store-num 庫存數：
      span 10
    //- .info-introduce-class 類別：
      span 有聲教材
</template>

<style lang="sass" scoped>
.reading-pen-info
  display: flex
  -webkit-box-pack: center
  justify-content: center
  -webkit-box-align: center
  align-items: flex-start
  margin-top: 5rem
  width: 90%
  max-width: 1100px
  margin: 5rem auto
  @media (max-width: 700px)
    display: block
  .click-cursor
    cursor: pointer
    &:hover
      opacity: .8
    &:active
      opacity: .6
  .info-imgbox
    width: 48%
    margin-right: 4%
    @media (max-width: 700px)
      width: 100%
    img
      border: 1px solid #f1f1f1
    .info-small-img
      margin-top: 10px
      height: 8rem
      display: flex
      .sm-img-item
        margin-left: 0
        height: 100%

  .info-introduce
    width: 48%
    @media (max-width: 700px)
      margin-top: 30px
      width: 100%
    .info-introduce-title
      color: #191d35
      font-weight: 500
      font-size: 18px
    .info-introduce-text
      margin-top: 2rem
      color: #606060
      font-size: 15px
    .info-introduce-store-num
      margin-top: 3rem
      color: #191d35
      font-weight: 500
      font-size: 16px
      span
        margin-left: 5px
        color: #2e50ff
    .info-introduce-class
      margin-top: .8rem
      color: #191d35
      font-weight: 500
      font-size: 16px
      span
        margin-left: 5px
        color: #707070
</style>
<script>
// import $ from 'jquery'
export default {
  name: 'readingPenInfo',
  components: {


  },
  props: {},
  data () {
    return {

    };
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}
</script>
