<template lang="pug">
//- header
header.d-flex.home-header
  //- 桌機版 nav
  HeaderNavDesktop.d-lg-block.d-md-block.d-sm-block.d-none(
    :arrHeaderNav="arrHeaderNav",
    @clickNav="clickNav",
    @clickStart="clickStart"
  )
  //- 手機版 nav
  HeaderNavMobile.d-lg-none.d-md-none.d-sm-none(
    :arrHeaderNav="arrHeaderNav",
    @clickNav="clickNav",
    @clickStart="clickStart"
  )
</template>
<script>
import HeaderNavDesktop from '@/common/header-nav-desktop'
import HeaderNavMobile from '@/common/header-nav-mobile'
// import $ from 'jquery'
export default {
  name: 'homeHeader',
  components: {
    HeaderNavDesktop,
    HeaderNavMobile
  },
  props: {},
  data () {
    return {
      arrHeaderNav: this.$root.arrHeaderNav,
      intScrollTop: 0 // 畫面滾動了多少
    };
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    clickNav (_meta, _type = 1) {
      this.$root.nav_meta = _meta
      this.$root.goPage(_meta, _type)
      // this.$router.push({ name: _meta })
    },
    clickStart () {
      this.$root.goPage('student-login')
    },
  }
}
</script>
