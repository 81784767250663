<template lang="pug">
.login-main
  PageTop(page="login")
  StudentLoginForm(v-if="from === 'student'")
  TeacherLoginForm(v-if="from === 'teacher'")
</template>
<script>
// import $ from 'jquery'
import PageTop from '@/common/page-top'
import StudentLoginForm from '@/components/login/student-login-form'
import TeacherLoginForm from '@/components/login/teacher-login-form'
export default {
  name: 'loginMain',
  components: {
    PageTop,
    StudentLoginForm,
    TeacherLoginForm
  },
  props: ['from'],
  data () {
    return {

    };
  },
  computed: {

  },
  created () {
    this.$root.confirmIsLogin()
  },
  mounted () {

  },
  methods: {

  }
}
</script>
