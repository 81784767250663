<template lang="pug">
//- 外教師資表格畫面
.teachers-form-view
  .teachers-form.form
    .form-title 免費體驗課程

    //- 姓名
    .form-group
      .form-group-title 姓名
        span *
      input.form-group-input(
        type="text",
        placeholder="請輸入您的姓名",
        v-model="form_data.username"
      )

    //- 性別
    .form-group
      .form-group-title 性別
        span *
      .form-group-radio
        a.radio-option(
          @click="changeSex(1)",
          v-bind:class="form_data.gender === 1 ? 'color-active' : 'color-noactive'"
        ) 男性
        a.radio-option(
          @click="changeSex(2)",
          v-bind:class="form_data.gender === 2 ? 'color-active' : 'color-noactive'"
        ) 女性

    //- 年齡
    .form-group
      .form-group-title 年齡
        span *
      .select-box
        select.form-select-input(
          v-model="form_data.age",
          v-bind:class="form_data.age !== 0 && form_data.age !== '0' ? 'color-text' : 'color-gray'"
        )
          option(value="0") 請選擇您的年齡
          option(v-for="n in 100", :value="n", v-if="n > 17") {{ n }}
        img.selectimg(src="@/assets/image/arrow@3x.png")

    //- Email
    .form-group
      .form-group-title Email
        span *
      input.form-group-input(
        type="text",
        placeholder="請輸入您的信箱",
        v-model="form_data.email"
      )

    //- 手機號碼
    .form-group
      .form-group-title 手機號碼
        span *
      input.form-group-input(
        type="text",
        placeholder="請輸入您的手機號碼",
        v-model="form_data.phone"
      )

    //- 按鈕
    .form-group
      .form-group-button.button(@click="pushForm")
        span 立刻報名
</template>
<script>
export default {
  name: 'teachersFormView',
  components: {},
  props: [],
  data () {
    return {
      form_data: {
        name: '',
        gender: 1,
        age: 0,
        email: '',
        phone: '',
      }
    };
  },
  methods: {
    changeSex (_option) {
      this.form_data.gender = _option
    },
    pushForm () {
      if (!this.$data.form_data.username) {
        alert('請填入您的姓名喔')
        return false
      }
      if (!this.$data.form_data.age) {
        alert('請選擇您的年齡喔')
        return false
      }
      if (!this.$data.form_data.phone) {
        alert('請填入您的聯繫電話喔')
        return false
      }
      if (!this.$data.form_data.email) {
        alert('請填入您的聯繫郵箱喔')
        return false
      }
      // 送出表單
      this.$root.apis.newApplyCourse(this.form_data, this.processNewApplyCourseSuccess, this.processNewApplyCourseError)
    },
    async processNewApplyCourseSuccess (_response) {
      if (_response.body.error_code !== 0) {
        return this.processNewApplyCourseError(_response)
      }
      // 顯示成功資訊
      this.$root.useAlert(1, "登記體驗課程", "登記成功", "瞭解")
      // 重置資料
      this.form_data = { name: '', gender: 1, age: 0, email: '', phone: '', }
    },
    async processNewApplyCourseError (_error) {
      await this.$root.useAlert(1, "登記體驗課程", _error.body.message, "瞭解")
    },
  }
}
</script>
