<template lang="pug">
//- header - nav 選單 - 桌機版
.container-fluid
  nav.nav-desktop.row.align-items-center.flex-nowrap
    //- logo
    .col.text-align-left.flex-shrink-0
      img.nav-logo.cursor-pointer.float-left(
        src="@/assets/image/gv-oedu@3x.png",
        @click="$root.goHome()"
      )
      .clearfix

    //- 頁籤
    .col-auto.flex-grow-0.flex-shrink-0(
      v-for="nav in arrHeaderNav",
      v-if="!$root.hide_page_status"
    )
      a.cursor-pointer.nav-link(
        @click="clickNav(nav.meta, nav.type)",
        v-bind:class="$root.nav_meta === nav.meta ? 'color-active font-weight-bold' : ''"
      ) {{ nav.title }}

    //- 學習按鈕
    .col.text-align-right.flex-shrink-0
      .login-header.login-before(
        v-if="!$root.login_status",
        v-bind:class="!$root.hide_page_status ? 'header-btn-btm' : ''"
      )
        a.button.btn-default.btn-sm.btn-width-auto.header-btn(
          @click="clickStart()"
        )
          span 開始學習
</template>
<script>
export default {
  name: 'HeaderNavDeskTop',
  components: {},
  props: {
    arrHeaderNav: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
    };
  },
  methods: {
    clickNav (meta, type) {
      this.$emit('clickNav', meta, type);
    },
    clickStart () {
      this.$emit('clickStart');
    },
  }
}
</script>
