export default {
  type: 'zh-jp',
  form: {
    apply_title: '教師になるために申し込む',
    name: 'あなたの名前',
    name_pls: 'あなたの名前を入力してください',
    country: 'あなたの地域を選択してください〈複数選択可）',
    country_pls: 'お住まいの地域を選択してください',
    sex: 'あなたの性別',
    man: '男性',
    female: '女性',
    age: '年齢',
    age_pls: '年齢を選択してください',
    email: 'メールアドレス',
    email_pls: 'あなたのメールアドレスを入力してください',
    n_phone: '市内電話',
    n_phone_pls: '市内電話を入力してください',
    phone: '携帯電話',
    phone_pls: 'あなたの携帯電話を入力してください',
    teach_lan: 'あなたの教えられる言語を選択してください〈複数選択可）',
    teach_lan_pls: '言語を選択してください',
    delete: '削除',
    meet_time: '都合のいい面接時間',
    meet_time_pls: 'あなたの都合のいい面接時間を簡単に教えてください',
    resume: '個人の経歴と自己紹介',
    resume_pls: '簡単に経歴を自己の紹介を述べてください',

    sign_title: 'アカウントを作る',
    password: 'アカウントのパスワード',
    password_pls: 'アカウントのパスワードを入力してください',
    sec_password: 'パスワードの確認',
    sec_password_pls: 'あなたのアカウントのパスワードを確認してください',
    start_apply: '応募開始'
  },
}
