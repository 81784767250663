<template lang="pug">
//- 登入表格畫面
.login-form-view
  .login-form.form
    .form-title
      span(v-if="student_login_way === 1 || student_login_way === 2") 學員帳戶
      span(v-else) 透過行動條碼登入
    .form-text 歡迎使用課務系統，請登入您的帳戶

    //- 帳密登入
    LoginWay1(v-if="student_login_way === 1", @changeWay="changeWay")

    //- 手機號碼登入
    LoginWay2(v-if="student_login_way === 2", @changeWay="changeWay")

    //- 掃碼登入
    LoginWay3(v-if="student_login_way === 3", @changeWay="changeWay")
</template>
<script>
import LoginWay1 from '@/common/student-login/student-login-way1'
import LoginWay2 from '@/common/student-login/student-login-way2'
import LoginWay3 from '@/common/student-login/student-login-way3'
export default {
  name: 'loginFormView',
  components: {
    LoginWay1,
    LoginWay2,
    LoginWay3
  },
  props: ['from'],
  data () {
    return {
      student_login_way: 1,
    };
  },
  methods: {
    changeWay (_way) {
      this.student_login_way = _way
      if (_way === 2) {
        this.$root.openVideo('QgHdfMRMhYI')
      }
    },

  }
}
</script>
