<template lang="pug">
.reading-pen-detail
  .detail-tab
    .tab-item.click-cursor(
      @click="chagneTab(false)",
      :class="!tab_status ? 'tab-item-line' : ''"
    ) 使用說明
    .tab-item.click-cursor(
      @click="chagneTab(true)",
      :class="tab_status ? 'tab-item-line' : ''"
    ) 下載
  .tab-content
    //- 使用說明
    .content-1(v-if="!tab_status")
      .content-title 使用說明
      .content-text
        .text-row 1. 朗讀筆有聲教材可分為地球村會話系列、美爾頓證照檢定專業課程系列、兒少暨國高中英語系列、地球村美語叢書系列、地球村日韓叢書系列，5大系列有聲檔案請勿放在同一記憶卡內。
        .text-row 2. 朗讀有聲教材，需搭配朗讀筆及朗讀書才能使用。
        .text-row 3. 朗讀筆內需安置相對應音檔，檔案可於 https://edu1n1.com/material/readingPenQRcode 或瀏覽器搜尋edu1n1網站 進入主頁後於朗讀筆頁面 > 下載 點選需要的對應檔案
        .text-row 4. 下載AP3檔案，須複製到朗讀筆記憶卡內，並在記憶卡中先建置一個名為AP3的資料夾，將檔案放在其內切勿隨意更改檔名。
        .text-row 5. 朗讀筆安裝音檔後，請先點選『點擊開始閱讀』，系統播放此書書名後，便能正式進入學習了。
        .text-row 6. 朗讀筆可點擊書本內小喇叭或灰色網點處，即會發聲朗讀學習。請不要將朗讀筆一直放在網點上造成重複判讀，會導致無法順利朗讀。
        .text-row 7. 朗讀筆長期沒有使用，請移除後方電池，以免電池漏液，造成朗讀筆損壞。
    //- 下載
    .content-2(v-else)
      .download-group(v-for="(download, index) in downloads")
        .download-group-head.click-cursor(@click="turnDownloadItem(index)")
          .download-group-title {{ download.group_title }}
          .download-group-arrow-btn
            .fa.fa-angle-down(v-if="!download.group_status")
            .fa.fa-angle-up(v-else)
        .download-group-content(v-if="download.group_status")
          .download-group-item(v-for="(book, index) in download.group_data")
            .download-item-title {{ book.name }}
            .download-item-text {{ book.directions }}
            .download-item-btn.click-cursor(
              @click="openDownLoad(book.files[0].url)"
            ) 下載
</template>
<script>
export default {
  name: 'readingPenDetail',
  components: {
  },
  props: {},
  data () {
    return {
      page: 1,
      searchPrePageMin: 1,
      searchPrePageMax: 1,
      tab_status: false,
      downloads: [
        {
          group_title: '朗讀筆檔案下載清單',
          group_status: true,
          group_data: []
        },
        // {
        //   group_title: '第二個項目',
        //   group_status: false,
        //   group_data: [
        //     {
        //       item_title: '項目1-1',
        //       item_info: '項目說明1-1'
        //     },
        //     {
        //       item_title: '項目1-2',
        //       item_info: '項目說明1-2'
        //     },
        //     {
        //       item_title: '項目1-3',
        //       item_info: '項目說明1-3'
        //     }
        //   ]
        // }
      ]
    };
  },
  computed: {

  },
  mounted () {

  },
  created () {
    this.getBookList()
  },
  methods: {
    // 切換tab
    chagneTab (_type) {
      this.tab_status = _type
    },
    // 下載項目開關
    turnDownloadItem (_index) {
      this.downloads[_index].group_status = !this.downloads[_index].group_status
    },
    // 獲得教材清單
    async getBookList () {
      await this.$http.get('manager/AudioBook/getList', {
        params: {
          type: 'website',
          page: this.page
        }
      }).then(async function (_response) {
        if (_response.body.error_code !== 0) {
          console.log('=> 教材獲取失敗: ', _response.body.message);
        }
        // 處理獲得的資料
        this.downloads[0].group_data = await _response.body.data
        // 處理分頁相關功能
        this.page = _response.body.page
        this.searchPrePageMin = _response.body.preMin
        this.searchPrePageMax = _response.body.preMax
      }, function () {

      })
    },
    // 開啟下載視窗
    openDownLoad (_url) {
      window.open(this.$root.siteUri + _url)
    }
  }
}
</script>
<style lang='sass' scoped>
.reading-pen-detail
  width: 90%
  max-width: 1100px
  margin: 4rem auto 6rem auto
  .click-cursor
    cursor: pointer
    &:hover
      opacity: .8
    &:active
      opacity: .6
  .detail-tab
    display: flex
    -webkit-box-pack: center
    justify-content: center
    -webkit-box-align: center
    align-items: flex-start
    border-bottom: 1px solid #e1e5e8
    .tab-item
      width: 100px
      height: 50px
      line-height: 50px
      cursor: pointer
      text-align: center
      color: #707070
      font-weight: 500
      font-size: 16px
    .tab-item-line
      border-bottom: 2px solid #2e50ff
      color: #191d35
  .tab-content
    padding-top: 30px
    .content-title
      margin-bottom: 10px
      color: #191d35
      font-weight: 600
    .content-text
      color: #606060
      font-size: 15px
      .text-row
        margin-bottom: 15px
        text-indent: -1rem
        margin-left: 1rem
    .download-group
      margin-bottom: 2.5rem
      .download-group-head
        border: 1px solid #eeeeee
        padding: 20px
        background: #fff
        display: flex
        -webkit-box-pack: center
        justify-content: left
        -webkit-box-align: center
        justify-items: center
        align-items: center
        .download-group-title
          color: #3f3f3f
          font-size: 20px
          font-weight: 500
          flex: 1 1 auto
        .download-group-arrow-btn
          padding: 0 10px
          font-weight: 600
      .download-group-content
        .download-group-item
          border: 1px solid #d8e1f0
          background: #f9fafc
          padding: 20px
          display: flex
          -webkit-box-pack: center
          justify-content: left
          -webkit-box-align: center
          justify-items: center
          align-items: center
          .download-item-title
            color: #3f3f3f
            font-weight: 600
            width: 180px
            margin-right: 20px
            font-size: 16px
            white-space: nowrap

          .download-item-text
            color: #9e9e9e
            font-weight: 500
            font-size: 16px
            margin-right: 20px
            flex: 1 1 auto

          .download-item-btn
            background: #2e50ff
            color: #ffffff
            padding: 5px 15px
            font-weight: 500
            font-size: 15px
            border-radius: 3px
            white-space: nowrap
</style>
