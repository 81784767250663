<template lang="pug">
.timetable-main
  PageTop.bg-gray(page="timetable")
  .container-fluid-lg
    //- 日期選擇
    //- TimeTableDate
    //- 篩選器
    TimeTableFilter(
      v-if="!use_cache",
      :set_time="set_time",
      :set_lang="set_lang",
      :set_course="set_course",
      :time_option_data="time_option_data",
      :lan_option_data="lan_option_data",
      :course_option_data="course_option_data",
      @changeTimeFilterOut="changeTimeFilter",
      @changeLanFilterOut="changeLanFilter",
      @changeCourseFilterOut="changeCourseFilter"
    )
    //- 表格主體
    TimeTable(
      v-if="show_course_table",
      :weeks="weeks",
      :date_filter="date_filter",
      :use_date_year="use_date_year",
      :use_date_week="use_date_week",
      :use_date_month="use_date_month"
    )
</template>
<script>
// import $ from 'jquery'
import PageTop from '@/common/page-top'
import TimeTableDate from './timetable-date.vue'
import TimeTableFilter from './timetable-filter.vue'
import TimeTable from './timetable-table.vue'
export default {
  name: 'timetableMain',
  components: {
    PageTop,
    TimeTableDate,
    TimeTableFilter,
    TimeTable
  },
  props: {},
  data () {
    return {
      use_cache: true,
      set_time: 'all',
      set_lang: 'all',
      set_course: 'all',
      time_option_data: [
        { id: "all", name: '整天', click_status: true },
        { id: "", name: '上午', click_status: false },
        { id: "", name: '下午', click_status: false },
        { id: "", name: '晚上', click_status: false }
      ],
      lan_option_data: [
        { id: "all", name: "全部", click_status: false },
        { id: "EN", name: "美語", click_status: false },
        { id: "JP", name: "日語", click_status: false },
        { id: "KO", name: "韓語", click_status: false }
      ],
      course_option_data: [
        { id: "all", name: "全部", click_status: false },
        { id: "MP", name: "會話班", click_status: false },
        { id: "LP", name: "證照班", click_status: false },
        // { id: "GM", name: "文法專班", click_status: false },
        // { id: "VB", name: "字彙加強班", click_status: false },
        // { id: "RW", name: "閱讀寫作專班", click_status: false },
        // { id: "YS", name: "在校生班", click_status: false },
        // { id: "CM", name: "商用語言班", click_status: false },
        // { id: "SS", name: "觀光會話班", click_status: false },
        // { id: "DM", name: "體驗課程", click_status: false },
        { id: "PC", name: "專業課程班", click_status: false },
        { id: "CD", name: "兒少班", click_status: false },
        // { id: "PS", name: "個教班", click_status: false },
      ],
      timeable_data_list: {},
      use_date_year: false,
      use_date_week: false,
      use_date_month: false,
      date_filter: false,
      show_course_table: false,
      weeks: [],
      courses: [],
      use_monday: false
    };
  },
  watch: {
    set_time () {
      this.makeWeekDates()
    },
    set_lang () {
      this.makeWeekDates()
    },
    set_course () {
      this.makeWeekDates()
    },
    '$root.system_ready' (_status) {
      if (_status) {
        this.processStartView()
      }
    }
  },
  computed: {

  },
  mounted () {

  },
  created () {
    if (this.$root.system_ready) {
      this.processStartView()
    }
  },
  methods: {
    processStartView () {
      // 獲取各時間資訊
      this.use_date_year = this.$root.common.getNowTime('year')
      this.use_date_week = this.$root.common.getNowTime('week')
      this.use_date_month = this.$root.common.getNowTime('month')
      this.date_filter = this.$root.common.getNowTime('date')
      this.use_date_week = (this.use_date_week === 0) ? 7 : this.use_date_week
      this.use_monday = this.getMondayDate(1 - this.use_date_week)

      console.log("本週一日期: ", this.use_monday)

      // 組合週曆陣列
      this.makeWeekDates()
    },
    // 獲取本週一日期 
    getMondayDate (_targetDay) {
      // 一日的總秒數
      let oneDaySeconds = 86400

      // 獲取今日的時間戳
      let nowTimestamp = this.$root.common.getNowTime('timestamp')

      // 獲取本週一的時間戳
      return (nowTimestamp + (oneDaySeconds * _targetDay))
    },
    // 組合各週日期陣列
    async makeWeekDates () {
      // 開啟載入中
      this.$root.useLoading()

      console.log('---> 組合各週日期陣列...')

      // 關閉課程列表
      this.show_course_table = false

      var vm = this
      // 一日的總秒數
      let oneDaySeconds = 86400

      // 獲取今日的時間戳
      // let nowTimestamp = this.$root.common.getNowTime('timestamp')
      // console.log('--> 今日的時間戳: ', nowTimestamp)

      // 本週週一時間戳作為基底
      let nowTimestamp = this.use_monday
      console.log('--> 基底的時間戳: ', nowTimestamp)

      // 多週陣列
      let Weeks = []

      // 取得自今日起的課程列表
      for (let dcount = 0; dcount <= 6; dcount++) {
        let dateTimestamp = (nowTimestamp + (oneDaySeconds * dcount))
        Weeks[dcount] = {
          date: vm.$root.common.getNowTime('date', dateTimestamp),
          day: vm.$root.common.getNowTime('date-day', dateTimestamp),
          week: vm.$root.common.getNowTime('week', dateTimestamp),
          course: {}
        }
        // 獲取各日期的課程資料
        await vm.getCourseList(vm.set_lang, vm.set_course, Weeks[dcount].date, dcount)
      }

      // 多日的課程列表
      await this.courses.forEach(async function (_courses) {
        // 單日的單項課程
        await _courses.forEach(async function (_course) {
          console.log('--> 處理[' + _course.start_date + '][' + _course.start_time + ']課程');
          // 需要建立的課程資料
          await Weeks.forEach(async function (week, day) {
            // 建立區塊
            if (!Weeks[day].course[_course.start_time]) {
              Weeks[day].course[_course.start_time] = []
            }
            // 當日課程
            if (week.date === _course.start_date) {
              // 置入課程資料
              await Weeks[day].course[_course.start_time].push(_course)
            }
          })
        })
      })

      await Weeks.forEach(async function (week, day) {
        Weeks[day].course = vm.sortObject(Weeks[day].course)
      })

      // 更新週曆資料
      this.weeks = Weeks

      // 開啟顯示課程表格
      this.show_course_table = true

      // 關閉載入中
      this.$root.closeLoading()
    },
    sortObject (obj) {
      return Object.keys(obj).sort().reduce(function (result, key) {
        result[key] = obj[key];
        return result;
      }, {});
    },
    // 變更獲取時段
    changeTimeFilter (_id) {
      this.set_time = _id
    },
    // 變更獲取語言
    changeLanFilter (_id) {
      this.set_lang = _id
    },
    // 獲取課程班別
    changeCourseFilter (_id) {
      this.set_course = _id
    },
    // ####################################
    // ### 處理課程相關資料
    // ####################################
    // 重新拉取課程列表
    async getCourseList (_langID, _courseType, _courseDate, _setIndex) {
      var vm = this

      // 獲得課程清單
      if (this.use_cache) {
        console.log('--> 預備請求獲取快取的[日期: ' + _courseDate + ']課程資料...')
        await this.$root.apis.getGroupCourseListByCache(_courseDate, function (_response) {
          // 處理課程清單
          vm.courses[_setIndex] = []
          _response.body.forEach(function (_item) {
            vm.courses[_setIndex].push(_item)
          })
        }, this.processCousreListError)
      }

      // 獲取即時資料
      else {
        console.log('--> 預備請求獲取[語言:' + _langID + '][類型:' + _courseType + '][日期: ' + _courseDate + ']課程資料...')
        await this.$root.apis.getGroupCourseList(_langID, _courseType, _courseDate, function (_response) {
          if (_response.body.error_code !== 0) {
            return this.processCousreListError(_response)
          }

          // 處理課程清單
          vm.courses[_setIndex] = []
          _response.body.data.forEach(function (_item) {
            vm.courses[_setIndex].push(_item)
          })
        }, this.processCousreListError)
      }

    },
    // 拉回課程列表失敗
    processCousreListError () {

    },
  }
}
</script>
