<template lang="pug">
//- 外教師資
.teachers-index.page-index
  Header
  TeacherMain
  Footer
</template>
<script>
// import $ from 'jquery'
import Header from '@/components/header'
import Footer from '@/components/footer'
import TeacherMain from '@/components/teachers/teachers-main'
export default {
  name: 'teachersIndex',
  components: {
    Header,
    Footer,
    TeacherMain
  },
  data () {
    return {
    };
  },
  async created () {
    this.$root.closeLoading()

  },
  destroyed () {
    this.$root.nav_meta = ''
  },
  mounted () {
    this.$root.nav_meta = 'teachers'
  },
  computed: {
  },
  methods: {

  }
}
</script>
<style lang="sass">
</style>
