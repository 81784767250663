<template lang="pug">
.rule-main
  .container-fluid-sm
    img.banner(src="@/assets/image/rule-img.png")
    .paper-main
      .paper-normal-text 本隱私政策最後更新時間為 2020年 1 月 1 日。

      .paper-group
        .paper-title 1. 我們的網站
        .paper-normal-text
          span 我們的網址是：
          span.paper-link
            a(href="https://www.edu1on1.com.tw/", target="_blank") https://www.edu1on1.com.tw/
        .paper-gray-content
          span 我們直接從您那裡收集某些數據，例如您自己輸入的信息、有關您參與課程的數據以及來自您與edu1on1連接的第三方平台的數據。我們還會自動收集一些數據，例如有關您的設備的信息以及您與我們的服務交互或花費時間使用的哪些部分。

      .paper-group
        .paper-title
          span 2. 您提供給我們的數據
        .paper-normal-text
          span 我們可能會根據您使用服務的方式收集來自您或關於您的不同數據。以下是一些示例，可幫助您更好地了解我們收集的數據。

      .paper-group
        .paper-title
          span 3. 我們如何獲取關於您的數據
        .paper-normal-text
          span 我們使用 cookie、網絡信標、分析服務和廣告提供商等工具來收集上面列出的數據。其中一些工具使您能夠選擇退出數據收集。

      .paper-group
        .paper-title
          span 4. 我們使用您的數據的目的
        .paper-normal-text.paper-indent-rule-level1
          span 1. 回答您的問題和疑慮；
        .paper-normal-text.paper-indent-rule-level1
          span 2. 向您發送管理消息和信息，包括來自教師和助教的消息、有關我們服務變更的通知以及我們協議的更新；
        .paper-normal-text.paper-indent-rule-level1
          span 3. 向您的無線設備發送推送通知以提供更新和其他相關消息（您可以從移動應用程序的“選項”或“設置”頁面進行管理）；

      .paper-group
        .paper-title
          span 5. 您對數據使用的選擇
        .paper-normal-text
          span 您可以選擇不向我們提供某些數據，但您可能無法使用服務的某些功能。
        .paper-normal-text.paper-indent-rule-level1
          span 要停止接收我們的促銷信息，您可以通過使用您收到的促銷信息中的取消訂閱機製或更改您帳戶中的電子郵件首選項來選擇退出。請注意，無論您的電子郵件首選項設置如何，我們都會向您發送有關服務的交易和關係消息，包括管理確認、訂單確認、有關服務的重要更新以及有關我們政策的通知。
        .paper-normal-text.paper-indent-rule-level1
          span 您使用的瀏覽器或設備可能允許您控制 cookie 和其他類型的本地數據存儲。您的無線設備還可以讓您控制是否收集和共享位置或其他數據。您可以通過 Adob​​e 的網站存儲設置面板管理 Adob​​e 的 LSO。
        .paper-normal-text.paper-indent-rule-level1
          span 要從參與公司獲取用於定制廣告的信息和控制 cookie，請參閱網絡廣告倡議和數字廣告聯盟的消費者選擇退出頁面，或者如果您位於歐盟，請訪問您的在線選擇網站。要選擇退出 Google 的展示廣告或自定義 Google 展示網絡廣告，請訪問 Google 廣告設置頁面。要退出 Taboola 的定向廣告，請參閱其 Cookie 政策中的退出鏈接。
        .paper-normal-text.paper-indent-rule-level1
          span 要直接更新您提供的數據，請登錄您的帳戶並隨時更新您的帳戶。

      .paper-group
        .paper-title
          span 6. 我們的兒童政策
        .paper-normal-text
          span 我們承認兒童的隱私利益，並鼓勵父母和監護人在兒童的在線活動和興趣中發揮積極作用。13 歲以下的兒童不應使用服務。如果我們得知我們收集了未滿這些年齡的兒童的個人數據，我們將採取合理措施將其刪除。
</template>
<script>
// import $ from 'jquery'
export default {
  name: 'ruleMain',
  components: {

  },
  props: {},
  data () {
    return {

    };
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}
</script>
