<template lang="pug">
//- 手機號碼登入
.way-2
  //- 手機號碼input
  .form-group
    .form-group-title 手機號碼
    //- 大視窗
    .d-lg-block.d-md-block.d-sm-block.d-none
      .phonecheck-input-lg
        select.phoneselect.padding-none.float-left(
          v-model="$root.country_code"
        )
          option(v-for="(code, index) in $root.country_codes", :value="code") {{ code }}

        input.phoneinput.float-left(
          type="tel",
          placeholder="輸入您的手機號碼",
          v-model="login_cellphone",
          spellcheck="false"
        )
        .clearfix

      .second-btn-lg(v-if="last_time <= 90 && last_time >= 1") {{ last_time }}
      .second-btn-lg(v-else-if="last_time < 1", @click="reciprocalTime()") 重新發送
      .send-btn-lg(v-else, @click="reciprocalTime()") 發送驗證碼
    .clearfix

    //- 小視窗
    .d-lg-none.d-md-none.d-sm-none.d-block
      .phonecheck-input-sm
        select.phoneselect.padding-none.float-left(
          v-model="$root.country_code"
        )
          option(v-for="(code, index) in $root.country_codes", :value="code") {{ code }}

        input.phoneinput.float-left(
          type="tel",
          placeholder="輸入您的手機號碼",
          v-model="login_cellphone",
          spellcheck="false"
        )
        .clearfix
      .second-btn-sm(v-if="last_time <= 90 && last_time >= 1") {{ last_time }}
      .second-btn-sm(v-else-if="last_time < 1", @click="reciprocalTime()") 重新發送
      .send-btn-sm(v-else, @click="reciprocalTime()") 發送驗證碼
    .clearfix

  //- 驗證碼
  .form-group
    .form-group-title 驗證碼
    input.form-group-input(
      type="number",
      placeholder="請輸入6位驗證碼",
      v-model="login_verinput"
    )

  //- 按鈕
  .form-group
    .form-group-button.button(@click="confirmPhoneCode")
      span 驗證

  //- 按鈕
  .form-group
    .form-group-button-1.button(@click="changeWay(1)")
      span 密碼登入

  //- 分隔線
  //- .form-group-divider-text
    .divider-line
    .divider-text 使用其他方式登入

  //- 按鈕
  //- .form-group
    .form-group-button-2.button(@click="changeWay(3)")
      span 透過行動條碼登入
  #send-messenger-button
</template>
<script>
import firebase from 'firebase'
export default {
  name: '',
  components: {},
  props: [],
  data () {
    return {
      //- way-2
      login_cellphone: '',
      login_verinput: '',
      last_time: 91,
    };
  },
  created () {
    setTimeout(() => { this.loadFireBase() }, 250)
  },
  methods: {
    reciprocalTime () {
      // 處理倒數計時相關功能
      this.last_time = 90
      this.$options.interval = setInterval(this.runbyeverytime, 1000)
      // 開始請求發送驗證碼
      this.sendPhoneMessage()
    },
    runbyeverytime () {
      // 處理倒數計時秒數 90
      if (this.$data.last_time >= 1) {
        // console.log('--- runbyeverytime ---')
        this.$data.last_time = this.$data.last_time - 1
        // console.log('--> time: ' + this.$data.last_time)
      }
    },
    changeWay (_way) {
      this.$emit('changeWay', _way);
    },
    // Load FireBase
    loadFireBase () {
      // 使用 FireBase Phone Auth
      console.log('--> 載入 FireBase Phone Auth...')
      firebase.auth().languageCode = 'zhTw'
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('send-messenger-button', {
        'size': 'invisible',
        'callback': function (response) {
        }
      })
    },
    // 請求發送簡訊
    sendPhoneMessage () {
      // 清空驗證碼欄位
      this.phoneCode = ''
      // 組合電話號碼
      let phoneNumber = this.$root.common.getPhoneCode(this.$root.country_code, this.login_cellphone)
      this.$root.cellphoneStep = phoneNumber
      console.log('等待驗證的電話號碼: ' + phoneNumber)
      // 請求發送驗證簡訊
      this.$root.sendPhoneNumberCode(phoneNumber, this.openConfirmCodeInput)
    },
    // 請求驗證簡訊編號
    confirmPhoneCode () {
      this.$root.confirmPhoneNumberCode(this.$root.cellphoneStep, this.login_verinput, this.phoneNumberConfirm)
    },
    // 當電話號碼被驗證成功
    async phoneNumberConfirm (_phoneNumber) {
      // 請求進行登入操作
      await this.$root.apis.usePlatformByPhoneNumber(_phoneNumber, this.loginSuccess, this.loginError)
    },
    // 處理登入成功
    async loginSuccess (_response) {
      if (_response.body.error_code !== 0) {
        this.loginError(_response)
        return
      }
      // 處理登入成功的操作
      await this.$root.apis.getSystemInfo()
      // 處理登入後的資訊
      await this.$root.confirmIsLogin()
    },
    // 處理登入失敗
    async loginError (_error) {
      await this.$root.useAlert(1, "登入發生問題", _error.body.message, "瞭解")
    }
  }
}
</script>
