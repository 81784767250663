<template lang="pug">
//- 登入表格畫面
.login-form-view
  .login-form.form
    .form-title 教師登入

    .form-text 歡迎使用課務系統，請登入您的帳戶

    //- 帳號
    .form-group
      .form-group-title 帳號
        span *
      input.form-group-input(
        type="text",
        placeholder="請輸入您的帳號",
        v-model="login_account"
      )

    //- 密碼
    .form-group
      .form-group-title 密碼
      input.form-group-input(
        type="password",
        placeholder="請輸入您的密碼",
        v-model="login_password"
      )

    //- 按鈕
    .form-group
      .form-group-button.button
        span 登入

    //- 按鈕
    .form-group
      .form-group-button.button
        span 登入
</template>
<script>
export default {
  name: 'loginFormView',
  components: {},
  props: ['from'],
  data () {
    return {
      login_account: '',
      login_password: '',
    };
  },
  methods: {

  }
}
</script>
