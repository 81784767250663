<template lang="pug">
//- pageTop
.page-top
  img.top-bg(
    v-if="page === 'teachers' || page === 'apply' || page === 'rule' || page === 'privacy' || page === 'timetable' || page === 'reading-pen'",
    src="@/assets/image/page-bg.png"
  )

  .container-fluid
    img.top-bg1(v-if="page === 'login'", src="@/assets/image/page-bg1.png")

  //--- 大視窗
  .d-lg-block.d-md-block.d-sm-block.d-none
    .top-box(v-if="page === 'teachers'")
      .top-title.title-lg
        span 投資自己 掌握未來
      .top-text.text-lg
        span 外師學有專精、政府合格外師、優良專業中師、可自選適合教師
    .top-box(v-if="page === 'reading-pen'")
      .top-title.title-lg
        span 朗讀筆
      .top-text.text-lg
        span 有聲教材
    .top-box(v-if="page === 'apply'")
      .top-title.title-lg
        span 數位老師應聘資料
      .top-text.text-lg
        span 成為一位教師
    .top-box(v-if="page === 'rule'")
      .top-title.title-lg
        span 條款及細則
      .top-text.text-lg
        span 首頁 /
        span.span-000 條款及細則
    .top-box(v-if="page === 'privacy'")
      .top-title.title-lg
        span 隱私權政策
      .top-text.text-lg
        span 首頁 /
        span.span-000 隱私權政策

  //--- 小視窗
  .d-lg-none.d-md-none.d-sm-none.d-block
    .top-box(v-if="page === 'teachers'")
      .top-title.title-sm
        span 面向初學者，從零到滿分
      .top-text.text-sm
        span 外師學有專精、政府合格外師、
      .top-text.text-sm
        span 優良專業中師、可自選適合教師
    .top-box(v-if="page === 'reading-pen'")
      .top-title.title-sm
        span 朗讀筆
      .top-text.text-sm
        span 有聲教材
    .top-box(v-if="page === 'apply'")
      .top-title.title-sm
        span 數位老師應聘資料
      .top-text.text-sm
        span 成為一位教師
    .top-box(v-if="page === 'rule'")
      .top-title.title-sm
        span 條款及細則
      .top-text.text-sm
        span 首頁 /
        span.span-000 條款及細則
    .top-box(v-if="page === 'privacy'")
      .top-title.title-sm
        span 隱私權政策
      .top-text.text-sm
        span 首頁 /
        span.span-000 隱私權政策

  .top-box(v-if="page === 'timetable'")
    .top-title.title-lg
      span 團班課表
    .top-text.text-lg
      span 週週更新精進語言
</template>
<script>
export default {
  name: 'pageTop',
  components: {},
  props: ['page'],
  data () {
    return {
    };
  },
  methods: {

  }
}
</script>
