<template lang="pug">
.timetable-table
  table
    thead
      tr
        th.row-1-1
        th.row-2
          .date.on_date {{ weeks[0].day }}
          .week 星期{{ get_week(weeks[0].week) }}
        th
          .date.off_date {{ weeks[1].day }}
          .week 星期{{ get_week(weeks[1].week) }}
        th 
          .date.off_date {{ weeks[2].day }}
          .week 星期{{ get_week(weeks[2].week) }}
        th 
          .date.off_date {{ weeks[3].day }}
          .week 星期{{ get_week(weeks[3].week) }}
        th 
          .date.off_date {{ weeks[4].day }}
          .week 星期{{ get_week(weeks[4].week) }}
        th 
          .date.off_date {{ weeks[5].day }}
          .week 星期{{ get_week(weeks[5].week) }}
        th 
          .date.off_date {{ weeks[6].day }}
          .week 星期{{ get_week(weeks[6].week) }}
    tbody
      tr(v-for="(courses, index) in weeks[0].course")
        td.row-1 
          span {{ index }}
        td.row-2 
          tableItem(v-for="(course, index) in courses", :course="course")
        td 
          tableItem(
            v-for="(course, index) in weeks[1].course[index]",
            :course="course"
          )
        td
          tableItem(
            v-for="(course, index) in weeks[2].course[index]",
            :course="course"
          )
        td
          tableItem(
            v-for="(course, index) in weeks[3].course[index]",
            :course="course"
          )
        td
          tableItem(
            v-for="(course, index) in weeks[4].course[index]",
            :course="course"
          )
        td
          tableItem(
            v-for="(course, index) in weeks[5].course[index]",
            :course="course"
          )
        td
          tableItem(
            v-for="(course, index) in weeks[6].course[index]",
            :course="course",
            :last="true"
          )
</template>
<script>
import tableItem from './table-item.vue'
export default {
  name: 'timetableTable',
  components: {
    tableItem
  },
  props: [
    'weeks',
    'use_date_year',
    'use_date_week',
    'use_date_month',
    'use_date_date',
  ],
  data () {
    return {
      //- 顯示陣列數
      date_data_stataus: 1,
      //- 點擊的日期
      date_click: 0,
    };
  },
  watch: {
    // 當日期被改變時
    date_filter (_date) {
      // 獲取指定日期的 Timestamp
      let timestamp = new Date(_date).getTime() / 1000
      // 更新選擇的週數
      this.use_date_year = this.$root.common.getNowTime('year', timestamp)
      this.use_date_week = this.$root.common.getNowTime('week', timestamp)
      this.use_date_month = this.$root.common.getNowTime('month', timestamp)
    },
    // 當單/多週被切換時
    date_turn (_status) {
      if (_status) {
        this.$data.date_data_stataus = 3
      } else {
        this.$data.date_data_stataus = 1
        if (this.use_type === 'home') {
          this.date_click = this.$root.today_num
        }
      }
    }
  },
  methods: {
    // 獲取星期幾
    get_week (_week) {
      let weeks = ["日", "一", "二", "三", "四", "五", "六"]
      return weeks[_week]
    }
  }
}
</script>
