<template lang="pug">
//- 隱私權
.privacy-index.page-index
  Header
  PageTop.bg-gray(page="privacy")
  PrivacyMain
  Footer
</template>
<script>
// import $ from 'jquery'
import Header from '@/components/header'
import Footer from '@/components/footer'
import PageTop from '@/common/page-top'
import PrivacyMain from '@/components/privacy/privacy-main'

export default {
  name: 'privacyMain',
  components: {
    Header,
    Footer,
    PageTop,
    PrivacyMain
  },
  data () {
    return {
    };
  },
  async created () {
    this.$root.closeLoading()
  },
  mounted () {
  },
  computed: {
  },
  methods: {

  }
}
</script>
<style lang="sass">
</style>
