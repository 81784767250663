<template lang="pug">
.teachers-main
  PageTop.bg-gray(page="teachers")
  img.teachers-banner(src="@/assets/image/teacher-img.png")
  TeachersForm
</template>
<script>
// import $ from 'jquery'
import PageTop from '@/common/page-top'
import TeachersForm from '@/components/teachers/teachers-form'
export default {
  name: 'teachersMain',
  components: {
    PageTop,
    TeachersForm
  },
  props: {},
  data () {
    return {

    };
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}
</script>
