<template lang="pug">
.table-item(
  :course-type="course.course_classtype"
  v-bind:class="confirmColor(course.is_expired, course.course_lang, course.course_classtype)"
)
  .item-type
    img.type-img(v-if="course.course_classtype === 'MS' || course.course_classtype === 'CD' || course.course_classtype === 'LP'" src="@/assets/image/m-pic-03.jpg")
    img.type-img(v-else-if="course.course_classtype === 'QT'" src="@/assets/image/m-pic-01.png")
    img.type-img(v-else src="@/assets/image/course.png")
    .type-text
      span(v-for="courseType in $root.total_course", v-if="course.course_classtype === courseType.id") {{ courseType.name }}
  .item-name {{ course.title }}
  .item-teacher {{ course.coures_teacher.name }}
  .item-time {{ course.start_time }}

  .hide-box(v-if="!last")
    .box-group
      .box-title 課程進度
      .box-text {{ course.course_material }}
    .box-group
      .box-title 課程描述
      .box-text {{ course.course_directions }}
      .box-title 課程教材
      .textbook-text
        img.textbook-img(src="@/assets/image/pdf-icon.png")
        span {{ course.course_material }}
    .box-group.text-align-center(
      v-if="$root.user.id",
      @click="goCourse(course.course_id)"
    )
      .box-button 前往課程

  .hide-box-last(v-if="last")
    .box-group
      .box-title 課程進度
      .box-text {{ course.course_material }}
    .box-group
      .box-title 課程描述
      .box-text {{ course.course_directions }}
    //- .box-group
      .box-title 課程教材
      .textbook-text
        img.textbook-img(src="@/assets/image/pdf-icon.png")
        span {{ course.course_material }}
    .box-group.text-align-center(
      v-if="$root.user.id",
      @click="goCourse(course.course_id)"
    )
      .box-button 前往課程
</template>
<script>
export default {
  name: 'tableItem',
  components: {},
  props: ['course', 'last'],
  data () {
    return {
    };
  },
  methods: {
    //   v-bind:class="course.course_lang === 'en' ? 'item-en' : [course.course_lang === 'ja' ? 'item-jp' : 'item-ko']"

    // 確認語系對應的顏色
    confirmColor (_isExpired = false, _courseLang = '', _courseType = '') {
      // 當課程已經結束
      if (_isExpired) {
        // return 'bg-gray-1'
      }

      // 根據語言判斷顏色
      let color = ''
      switch (_courseLang) {
        case 'en':
          color = 'item-en'
          break

        case 'ja':
          color = 'item-jp'
          break

        default:
          color = 'item-ko'
          break
      }

      let textcolor = ''
      switch (_courseType) {
        case 'MS':
        case 'CD':
        case 'LP':
          textcolor = 'course-ms'
          break;
        case 'QT':
          textcolor = 'course-qt'
          break;
      }

      return color + ' ' + textcolor
    },
    goCourse (_courseID) {
      this.$root.goPage('/student/course/' + _courseID, 2)
    }
  }
}
</script>

<style lang="sass">
  .bg-gray-1
    background: #D4D4D4

  .course-ms
    .item-type .type-text
      color: #035EF5 !important
    .item-name
      color: #035EF5 !important
    .item-teacher
      color: #035EF5 !important
    .item-time
      color: #035EF5 !important
  .course-qt
    .item-type .type-text
      color: #663399 !important
    .item-name
      color: #663399 !important
    .item-teacher
      color: #663399 !important
    .item-time
      color: #663399 !important
</style>