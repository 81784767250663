<template lang="pug">
#app
  router-view
  //- loading畫面
  loadingView(:isVisible="$root.isLoading")
  //- 畫面縮小後partbar
  VideoWarp(v-if="$root.isVideo")
  //- cookie-bar
  CookieBar.animated-3(
    v-if="$root.isCookie",
    v-bind:class="$root.isCookie_animate ? 'slideInUp' : 'slideOutDown'"
  )
  GoTopButton
  //- alert
  AlertView(v-if="$root.isAlert")
</template>

<script>
import loadingView from '@/components/loading'
import VideoWarp from '@/components/home/video-wrap'
import CookieBar from '@/components/cookie-bar'
import GoTopButton from '@/common/go-top-button'
import AlertView from '@/components/alert'
export default {
  name: 'App',
  components: {
    loadingView,
    VideoWarp,
    CookieBar,
    GoTopButton,
    AlertView
  }
}
</script>
<style lang='sass'>
#app
  background: #ebeff3
</style>
