<template lang="pug">
.home-banner
  .banner-100(v-if="showBanner")
    carousel(
      items="1",
      :autoplay="true",
      :dots="false",
      :nav="false",
      :margin="15",
      :loop="true"
    )
      .nav-content(v-for="(banner, index) in banners")
        img.nav-img(
          :src="banner.image",
          @click="$root.openBanner(banner.content.image)"
        )
  //- .banner-100(v-else)
    img.banner.d-lg-block.d-md-block.d-sm-none.d-xs-none.d-none(
      src="@/assets/image/big-banner-v10.jpg"
    )
    img.banner.d-lg-none.d-md-none.d-sm-block.d-xs-block.d-block(
      src="@/assets/image/big-banner-v10.jpg"
    )

  //- 在banner下面的
  .d-lg-none.d-md-none.d-sm-block.d-xs-block
    .banner-text
      .title-text
        .title-text.font-weight-bold.font-size-lg 全球華人最大美日韓語
        .title-text.font-weight-bold.font-size-lg 真人直播互動平台
      .group-ps
        .ps-text 100%政府合格中外籍教師教學
</template>
<script>
// import $ from 'jquery'
import carousel from 'v-owl-carousel'
export default {
  name: 'homeBanner',
  components: {
    carousel
  },
  props: {},
  data () {
    return {
      showBanner: false,
      banners: [],
    };
  },
  created () {
    this.getBannerData()
  },
  methods: {
    // 獲取 Banner 資料
    async getBannerData () {
      await this.$root.apis.getBannerInfo(this.processGetBannerSuccess, this.processStatusError)
    },
    // 成功獲取 Banner
    async processGetBannerSuccess (_response) {
      this.banners = _response.body
      setTimeout(() => {
        this.showBanner = true
      }, 500);
    }
  }
}
</script>
