<template lang="pug">
//- 外教師資表格畫面
.apply-form-view
  //- 表格一號
  .apply-form.form
    .d-lg-block.d-md-block.d-sm-block.d-none
      .form-title {{ langdata.form.apply_title }}
    .d-lg-none.d-md-none.d-sm-none.d-block
      .form-title-sm {{ langdata.form.apply_title }}

    //- 表格語言
    .form-group
      .form-group-radio
        a.radio-option-3(
          @click="changeShowLan('ch')",
          v-bind:class="show_lan === 'ch' ? 'color-active' : 'color-noactive'"
        ) 中語
          span (Chinese)
        a.radio-option-3(
          @click="changeShowLan('jp')",
          v-bind:class="show_lan === 'jp' ? 'color-active' : 'color-noactive'"
        ) 日語
          span (Japanese)
        a.radio-option-3(
          @click="changeShowLan('ko')",
          v-bind:class="show_lan === 'ko' ? 'color-active' : 'color-noactive'"
        ) 韓語
          span (Korean)

    //- 姓名
    .form-group
      .form-group-title {{ langdata.form.name }}
        span *
      input.form-group-input(
        type="text",
        :placeholder="langdata.form.name_pls",
        v-model="form_data.name"
      )

    //- 地區
    .form-group
      .form-group-title {{ langdata.form.country }}
        span *
      .select-box
        select.form-select-input.color-gray(
          v-bind:class="form_data.area_data.length !== 0 ? 'form-select-input-mu' : 'color-noactive'",
          v-model="form_data.area"
        )
          option(value="0") {{ langdata.form.country_pls }}
          option(
            v-for="(country, index) in country",
            :value="index",
            v-if="country.is_show"
          ) {{ country.country }}

        img.selectimg(src="@/assets/image/arrow@3x.png")

      .form-group-select-mutiply(v-if="form_data.area_data.length !== 0")
        .mutiply-box(v-for="area in form_data.area_data")
          a(
            @click="choiceArea(area)",
            v-bind:class="area_del === area ? 'color-active' : 'color-noactive'"
          ) {{ area }}
          span 、

      .del-button.cursor-pointer(
        v-if="form_data.area_data.length !== 0",
        @click="delArea()"
      ) {{ langdata.form.delete }}

    //- 性別
    .form-group
      .form-group-title {{ langdata.form.sex }}
        span *
      .form-group-radio
        a.radio-option(
          @click="changeSex('man')",
          v-bind:class="form_data.sex === 'man' ? 'color-active' : 'color-noactive'"
        ) {{ langdata.form.man }}
        a.radio-option(
          @click="changeSex('female')",
          v-bind:class="form_data.sex === 'female' ? 'color-active' : 'color-noactive'"
        ) {{ langdata.form.female }}

    //- 年齡
    .form-group
      .form-group-title {{ langdata.form.age }}
        span *
      .select-box
        select.form-select-input(
          v-model="form_data.age",
          v-bind:class="form_data.age !== 0 && form_data.age !== '0' ? 'color-text' : 'color-gray'"
        )
          option(value="0") {{ langdata.form.age_pls }}
          option(v-for="n in 100", :value="n", v-if="n > 17") {{ n }}
        img.selectimg(src="@/assets/image/arrow@3x.png")

    //- Email
    .form-group
      .form-group-title {{ langdata.form.email }}
        span *
      input.form-group-input(
        type="text",
        :placeholder="langdata.form.email_pls",
        v-model="form_data.email"
      )

    //- 聯絡電話(市話)
    .form-group
      .form-group-title {{ langdata.form.n_phone }}
      input.form-group-input(
        type="text",
        :placeholder="langdata.form.n_phone_pls",
        v-model="form_data.n_phone"
      )

    //- 手機號碼
    .form-group
      .form-group-title {{ langdata.form.phone }}
        span *
      input.form-group-input(
        type="text",
        :placeholder="langdata.form.phone_pls",
        v-model="form_data.phone"
      )

    //- 可教學的語言(多選)
    .form-group
      .form-group-title {{ langdata.form.teach_lan }}
        span *
      .select-box
        select.form-select-input.color-gray(
          v-bind:class="form_data.teach_lan_data.length !== 0 ? 'form-select-input-mu' : 'color-noactive'",
          v-model="form_data.teach_lan"
        )
          option(value="0") {{ langdata.form.teach_lan_pls }}
          option(
            v-for="(lan, index) in lans",
            :value="index",
            v-if="lan.is_show"
          ) {{ lan.language }}

        img.selectimg(src="@/assets/image/arrow@3x.png")

      .form-mu-select
        .form-group-select-mutiply(
          v-if="form_data.teach_lan_data.length !== 0"
        )
          .mutiply-box(v-for="lan in form_data.teach_lan_data")
            a(
              @click="choiceLan(lan)",
              v-bind:class="lan_del === lan ? 'color-active' : 'color-noactive'"
            ) {{ lan }}
            span 、

        .del-button.cursor-pointer(
          v-if="form_data.teach_lan_data.length !== 0",
          @click="delLan()"
        ) {{ langdata.form.delete }}

    //- 方便面試時間
    .form-group
      .form-group-title {{ langdata.form.meet_time }}
      textarea.form-group-textarea(
        :placeholder="langdata.form.meet_time_pls",
        v-model="form_data.meet_time"
      )

    //- 個人履歷/自傳
    .form-group
      .form-group-title {{ langdata.form.resume }}
      textarea.form-group-textarea(
        :placeholder="langdata.form.resume_pls",
        v-model="form_data.resume"
      )

  //- 表格二號
  .apply-form.form
    .d-lg-block.d-md-block.d-sm-block.d-none
      .form-title {{ langdata.form.sign_title }}
    .d-lg-none.d-md-none.d-sm-none.d-block
      .form-title-sm {{ langdata.form.sign_title }}

    //- 密碼
    .form-group
      .form-group-title {{ langdata.form.password }}
      input.form-group-input(
        type="password",
        :placeholder="langdata.form.password_pls",
        v-model="form_data.password"
      )

    //- 再次確認密碼
    .form-group
      .form-group-title {{ langdata.form.sec_password }}
      input.form-group-input(
        type="password",
        :placeholder="langdata.form.sec_password_pls",
        v-model="form_data.sec_password"
      )

    //- 按鈕
    .form-group
      .form-group-button.button
        span {{ langdata.form.start_apply }}
</template>
<script>
import languageTW from '@/assets/apply-form-lan/apply-form-tw'
import languageJP from '@/assets/apply-form-lan/apply-form-jp'
import languageKO from '@/assets/apply-form-lan/apply-form-ko'
export default {
  name: 'applyFormView',
  components: {},
  props: [],
  data () {
    return {
      //-顯示語言
      show_lan: 'ch',
      langdata: {},
      //-地區暫存
      area_del: '',
      //-語言暫存
      lan_del: '',
      //- 表單內容
      form_data: {
        name: '',
        area: '0',
        area_data: [],
        sex: 'man',
        age: 0,
        email: '',
        n_phone: '',
        phone: '',
        teach_lan: '0',
        teach_lan_data: [],
        meet_time: '',
        resume: '',
        password: '',
        sec_password: ''
      },
      //- 語言data
      lans: [
        {
          id: 1,
          language: "美語(English)",
          is_show: true
        },
        {
          id: 2,
          language: "日語(Japanese)",
          is_show: true
        },
        {
          id: 3,
          language: "韓語(Korean)",
          is_show: true
        },
      ],
      //- 地區data
      country: [
        {
          id: 1,
          country: "阿根廷(Argentina)",
          is_show: true
        },
        {
          id: 2,
          country: "澳洲(Australia)",
          is_show: true
        },
        {
          id: 3,
          country: "孟加拉(Bangladesh)",
          is_show: true
        },
        {
          id: 4,
          country: "加拿大(Canada)",
          is_show: true
        },
        {
          id: 5,
          country: "中國(China)",
          is_show: true
        },
        {
          id: 6,
          country: "法國(France)",
          is_show: true
        },
        {
          id: 7,
          country: "英國(United Kingdom)",
          is_show: true
        },
        {
          id: 8,
          country: "香港(Hong Kong)",
          is_show: true
        },
        {
          id: 9,
          country: "愛爾蘭(Ireland)",
          is_show: true
        },
        {
          id: 10,
          country: "英屬印度洋領地(British Indian Ocean Territory)",
          is_show: true
        },
        {
          id: 11,
          country: "日本(Japan)",
          is_show: true
        },
        {
          id: 12,
          country: "韓國(Korea, Republic of)",
          is_show: true
        },
        {
          id: 13,
          country: "馬來西亞(Malaysia)",
          is_show: true
        },
        {
          id: 14,
          country: "紐西蘭(New Zealand)",
          is_show: true
        },
        {
          id: 15,
          country: "新加坡(Singapore)",
          is_show: true
        },
        {
          id: 16,
          country: "臺灣(Taiwan)",
          is_show: true
        },
        {
          id: 17,
          country: "美國(United States)",
          is_show: true
        },
        {
          id: 18,
          country: "南非(South Africa)",
          is_show: true
        }
      ]
    };
  },
  watch: {
    'form_data.area' (_index) {
      if (_index !== '0') {

        var _content = this.country[_index].country

        console.log(_index)
        console.log('選擇了 =====> ' + _content)
        //- 丟到 area_data
        this.form_data.area_data.push(_content)
        //- 隱藏已選區域
        this.country[_index].is_show = false
        //- 重置
        this.form_data.area = '0'
        //- 選擇已選取的區域
        this.choiceArea(_content)
      }
    },
    'form_data.teach_lan' (_index) {
      if (_index !== '0') {
        var _content = this.lans[_index].language

        console.log(_index)
        console.log('選擇了 =====> ' + _content)
        //- 丟到 teach_lan_data
        this.form_data.teach_lan_data.push(_content)
        //- 隱藏已選區域
        this.lans[_index].is_show = false
        //- 重置
        this.form_data.teach_lan = '0'
        //- 選擇已選取的區域
        this.choiceLan(_content)
      }
    }
  },
  created () {
    this.changeShowLan('ch')
  },
  methods: {
    //- 顯示語言切換
    changeShowLan (_option) {
      this.show_lan = _option
      if (_option === 'ch') {
        this.langdata = languageTW
      }
      if (_option === 'jp') {
        this.langdata = languageJP
      }
      if (_option === 'ko') {
        this.langdata = languageKO
      }
    },

    //- 性別切換
    changeSex (_option) {
      this.form_data.sex = _option
    },


    //------  語言多選操作
    //- 選擇已被選入的語言
    choiceLan (_lan) {
      this.lan_del = _lan
    },
    //- 刪除已被選入的語言
    delLan () {
      var vm = this
      //- 把 lan 列表的 show 打開
      this.lans.forEach(function (_lan, _lanIndex) {
        if (_lan.language === vm.lan_del) {
          vm.lans[_lanIndex].is_show = true
        }
      })
      //- 把已被選擇的刪除
      this.form_data.teach_lan_data.forEach(function (_lan, _lanIndex) {
        if (_lan === vm.lan_del) {
          vm.form_data.teach_lan_data.splice(_lanIndex, 1)
        }
      })
      //- 重置
      var sm_index = this.form_data.teach_lan_data.length
      this.lan_del = this.form_data.teach_lan_data[sm_index - 1]
    },

    //------  地區多選操作
    //- 選擇已被選入的地區
    choiceArea (_area) {
      this.area_del = _area
    },
    //- 刪除已被選入的地區
    delArea () {
      var vm = this
      //- 把 country 列表的 show 打開
      this.country.forEach(function (_country, _countryIndex) {
        if (_country.country === vm.area_del) {
          vm.country[_countryIndex].is_show = true
        }
      })
      //- 把已被選擇的刪除
      this.form_data.area_data.forEach(function (_country, _countryIndex) {
        if (_country === vm.area_del) {
          vm.form_data.area_data.splice(_countryIndex, 1)
        }
      })
      //- 重置
      var sm_index = this.form_data.area_data.length
      this.area_del = this.form_data.area_data[sm_index - 1]
    }
  }
}
</script>
