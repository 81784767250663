<template lang="pug">
//- 手機號碼登入
.way-2
  //- 手機號碼input
  .form-group

  .form-group
    //- QR CODE 
    .code-box
      qrcode.code-size(:value="fjqoelefwqv243952nfo21nv13cr1cn083n1fon20ec82")
      //- 重整按鈕
      .code-refresh-btn.cursor-pointer
        img.code-refresh-img(src="@/assets/image/refresh@3x.png")
        .code-refresh-text 更新行動條碼

  .form-text 請開啟地球村線上學行動條碼掃描器，並掃描此行動條碼。

  //- 按鈕
  .form-group
    .form-group-button-1.button(@click="changeWay(1)")
      span 密碼登入

  //- 按鈕
  .form-group.mt-1
    .form-group-button-2.button(@click="changeWay(2)")
      span 手機號碼登入
</template>
<script>
export default {
  name: '',
  components: {},
  props: [],
  data () {
    return {

    };
  },
  methods: {
    changeWay (_way) {
      this.$emit('changeWay', _way);
    },
  }
}
</script>
