<template lang="pug">
transition(name="fade")
  .cookie-bar-wrapper
    //- 大視窗
    .d-lg-block.d-md-block.d-sm-none.d-none
      .cookie-bar-content
        .cookie-bar-text 我們使用 cookie 來確保在我們的網站上為您提供最佳體驗。如果您繼續使用本網站，我們將假設您對此感到滿意。
        .button.btn-default.btn-sm.btn-width-auto.cookie-btn(
          @click="$root.closeCookie()"
        )
          span 好的
        .button.btn-default.btn-sm.btn-width-auto.cookie-btn(
          @click="$root.goPage('privacy')"
        )
          span 隱私政策

    //- 小視窗
    .d-lg-none.d-md-none.d-sm-block.d-block
      .cookie-bar-content-sm
        .cookie-bar-text 我們使用 cookie 來確保在我們的網站上為您提供最佳體驗。如果您繼續使用本網站，我們將假設您對此感到滿意。
        .cookie-btn-box-sm
          .button.btn-default.btn-sm.btn-width-auto.cookie-btn-sm(
            @click="$root.closeCookie()"
          )
            span 好的
          .button.btn-default.btn-sm.btn-width-auto.cookie-btn-sm(
            @click="$root.goPage('privacy')"
          )
            span 隱私政策
</template>
<script>
export default {
  name: 'Cookiebar',
  props: {
  },
  components: {
  },
  computed: {

  },
  created () {
  },
  data () {
    return {

    };
  }
}
</script>
