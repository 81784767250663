import Vue from 'vue'
import VueResource from 'vue-resource'
import VueCookies from 'vue-cookies'
Vue.use(VueResource)
Vue.use(VueCookies)

// ================
Vue.http.options.root = process.env.VUE_APP_API_URL
Vue.http.interceptors.push(function () {
    Vue.http.headers.common['OAUTH-API-KEY'] = Vue.localStorage.get('api-token')
    Vue.http.headers.common['Content-Type'] = 'application/json'
})

var project
export default class APIs {
    constructor(_vm) {
        project = _vm
    }

    /**
     * 獲取後端系統狀態
     * @param {*} _success 
     * @param {*} _error 
     */
    async getSystemStatus (_success, _error) {
        await Vue.http.get('status').then(_success, _error)
    }

    /**
     * 獲取 Token 資訊
     */
    async getSystemInfo () {
        // 啟動載入中畫面
        project.useLoading()
        await Vue.http.get('member/getStatus', { params: { ver: 'course-app' } }).then(function (_response) {
            // 關閉載入中畫面
            project.closeLoading()
            // 獲得目前回傳的資料
            let response = _response.body
            // 讓系統開始運作
            project.system_ready = true
            // 判斷是否有狀況
            if (response.error_code === 0) {
                // 處理 API Token
                project.auth_token = response.api_key
                Vue.localStorage.set('api-token', project.auth_token)

                // 處理獲得的資訊
                if (response.UserData.login_platform !== 1 && response.UserData.login_platform !== 5) {
                    return
                }

                // 處理獲得的個人資料
                project.user = response.UserData.uData
            }
        })
    }

    /**
     * 獲得系統設定參數
     * @param {*} _type 
     */
    async getSystemSettings (_type = '', _success, _error) {
        await Vue.http.get('manager/setting/getSettings', {
            params: {
                type: _type
            }
        }).then(_success, _error)
    }

    /**
     * 請求後端增加體驗課程紀錄
     * @param {*} _form 
     * @param {*} _success 
     * @param {*} _error 
     */
    async newApplyCourse (_form, _success, _error) {
        await Vue.http.post('client_api/new_apply', _form).then(_success, _error)
    }

    /**
     * 更新學員資料
     * @param {*} _type 
     * @param {*} _data 
     * @param {*} _success 
     * @param {*} _error 
     * @returns 
     */
    async setUserData (_type = 1, _data = false, _success, _error) {
        var formData

        if (_type === 1) {
            formData = this.$data.userData
            formData.formType = 1
            // ===========================
            if (formData.userNewPwd !== '' || formData.userNewPwd2 !== '') {
                if (formData.userNewPwd !== formData.userNewPwd2) {
                    this.showAlert('需要變更的密碼不相同喔')
                    return false
                }
                // =========================================
                formData.password = formData.userNewPwd
                // =========================================
            }
        }

        if (_type === 2) {
            formData = {
                formType: 2,
                image: _data
            }
        }

        if (_type === 4) {
            formData = {
                formType: 4,
                userCPhone: _data
            }
        }

        await Vue.http.post('student/center/baseSetting', formData).then(_success, _error)
    }

    /**
     * 請求使用電話號碼向後端獲取資料
     * @param {*} _userPhone 
     * @param {*} _success 
     * @param {*} _error 
     */
    async usePlatformByPhoneNumber (_userPhone = false, _success, _error) {
        await Vue.http.post('member/login', { selectAcc: 5, userPhone: _userPhone }).then(_success, _error)
    }

    /**
     * 請求使用帳密向後端獲取資料
     * @param {*} _loginData 
     * @param {*} _success 
     * @param {*} _error 
     */
    async usePlatformByPassword (_loginData = false, _success, _error) {
        await Vue.http.post('member/login', _loginData).then(_success, _error)
    }

    /**
     * 請求更新學員資料
     * @param {*} _detail 
     */
    async putStudentDetail (_detail = false, _success, _error) {
        await Vue.http.post('student/center/baseSetting', {
            formType: 3,
            userName: _detail.name,
            userGender: _detail.gender,
            userCity: _detail.city,
        }).then(_success, _error)
    }

    /**
     * 請求獲取學員指定日期的推薦課程
     * @param {*} _courseType 
     * @param {*} _langID 
     * @param {*} _courseDate 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getStudentRecommendCourse (_courseType, _langID, _courseDate, _success, _error) {
        await Vue.http.get('student/course/getStudentTodayRecommend/' + _courseType + '/' + _langID + '/' + _courseDate).then(_success, _error)
    }

    /**
     * 請求獲取學員課表
     * @param {*} _success 
     * @param {*} _error 
     */
    async getStudentCourseList (_courseLang = false, _courseType = false, _courseDate = false, _success, _error) {
        await Vue.http.get('student/course/getStudentCourses/' + _courseLang + '/' + _courseType + '/' + _courseDate).then(_success, _error)
    }

    /**
     * 獲取學員今日的課程紀錄
     * @param {*} _courseLang 
     * @param {*} _courseType 
     * @param {*} _courseDate 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getStudentTodayCourseList (_courseLang = false, _courseType = false, _courseDate = false, _success, _error) {
        await Vue.http.get('student/course/getStudentTodayCourses/' + _courseLang + '/' + _courseType + '/' + _courseDate).then(_success, _error)
    }

    /**
     * 獲取學員舊的課程紀錄
     * @param {*} _courseLang 
     * @param {*} _courseType 
     * @param {*} _courseDate 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getStudentOldCourseList (_courseLang = false, _courseType = false, _courseDate = false, _success, _error) {
        await Vue.http.get('student/course/getStudentOldCourses/' + _courseLang + '/' + _courseType + '/' + _courseDate).then(_success, _error)
    }

    /**
     * 取得單一課程資料
     * @param {*} _courseID 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getCourseDetail (_courseID, _success, _error) {
        await Vue.http.get('student/course/getCourseDetail', {
            params: {
                course_id: _courseID
            }
        }).then(_success, _error)
    }

    /**
     * 請求獲取團體班課表
     * @param {*} _langID 
     * @param {*} _courseType 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getGroupCourseList (_langID, _courseType, _courseDate, _success, _error) {
        await Vue.http.get('student/website/getMainCourses/' + _courseType + '/' + _langID + '/' + _courseDate).then(_success, _error)
    }

    /**
     * 採用快取的方式獲得課程資料
     * @param {*} _chcheName 
     */
    async getGroupCourseListByCache (_chcheName, _success, _error) {
        await Vue.http.get(project.siteUri + '/resources/course/' + _chcheName + '.json').then(_success, _error)
    }

    /**
     * 預約團體班課程
     * @param {*} _courseID 
     * @param {*} _success 
     * @param {*} _error 
     */
    async registerGroupCourse (_courseID, _success, _error) {
        await Vue.http.get('student/course/newMainCourse', {
            params: {
                course_id: _courseID
            }
        }).then(_success, _error)
    }

    /**
     * 預約團體班課程
     * @param {*} _courseID 
     * @param {*} _success 
     * @param {*} _error 
     */
    async liveGroupCourse (_courseID, _success, _error) {
        await Vue.http.get('student/course/liveMainCourse', {
            params: {
                course_id: _courseID
            }
        }).then(_success, _error)
    }

    /**
     * 獲取學員點數交易紀錄
     * @param {*} _page 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getStudentPointTrades (_page = 1, _success, _error) {
        await Vue.http.get('student/center/getStudentTrades', {
            params: {
                coursePage: _page
            }
        }).then(_success, _error)
    }

    /**
     * 獲取學員點數方案清單
     * @param {*} _page 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getStudentPointPlans (_page = 1, _success, _error) {
        await Vue.http.get('student/center/getStudentPlans', {
            params: {
                coursePage: _page
            }
        }).then(_success, _error)
    }

    /**
     * 獲取學員購方案清單
     */
    async getALlProgramLists (_success, _error) {
        await Vue.http.get('student/programs/getProgramLists').then(_success, _error)
    }

    /**
     * 請求建立方案訂單
     * @param {*} _order 
     * @param {*} _success 
     * @param {*} _error 
     */
    async createStudentPlanOrder (_order, _success, _error) {
        await Vue.http.post('student/programs/createOrder', _order).then(_success, _error)
    }

    /**
     * Apple InPurchase 完成通報
     * @param {*} _order 
     * @param {*} _success 
     * @param {*} _error 
     */
    async appleInPurchaseSuccess (_orderNumber, _tradeNo, _success, _error) {
        await Vue.http.post('webhook/applePaymented/' + _orderNumber, {
            TradeNo: _tradeNo
        }).then(_success, _error)
    }

    /**
     * 通報 APP Log
     * @param {*} _log 
     */
    async putAppLog (_log) {
        await Vue.http.post('log/app', _log)
    }

    /**
     * 請求登出系統
     */
    async logout () {
        await Vue.http.get('member/logout').then(function (_response) {
            if (_response.body.error_code === 0) {
                // 登出後前往頁面
                project.goPage('first-page')
            }
        })
    }

    /**
     * 取得目前指定的顯示教材
     * @param {*} _success 
     * @param {*} _error 
     */
    async getMaterialDetail (_success, _error) {
        await Vue.http.get('student/website/getCourseMaterialDetail').then(_success, _error)
    }

    /**
     * 獲取首頁 Banner 資訊
     * @param {*} _success 
     * @param {*} _error 
     */
    async getBannerInfo (_success, _error) {
        await Vue.http.get('student/Ads/getBanners?app=website').then(_success, _error)
    }
}