<template lang="pug">
.apply-main
  PageTop.bg-gray(page="apply")
  ApplyForm
</template>
<script>
// import $ from 'jquery'
import PageTop from '@/common/page-top'
import ApplyForm from '@/components/apply/apply-form'
export default {
  name: 'applyMain',
  components: {
    PageTop,
    ApplyForm
  },
  props: {},
  data () {
    return {

    };
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}
</script>
