export default {
  type: 'zh-ko',
  form: {
    apply_title: '교사가되기 위해 지원',
    name: '성함',
    name_pls: '이름을 입력하십시오.',
    country: '당신의 수업 가능 지역을 다중 입력하십시오.',
    country_pls: '해당 지역을 선택하십시오',
    sex: '성별',
    man: '남성',
    female: '여성',
    age: '나이',
    age_pls: '나이를 선택하세요',
    email: '이메일',
    email_pls: '이메일을 입력하십시오.',
    n_phone: '연락 받을 전화 번호',
    n_phone_pls: '연락 받을 전화 번호를 입력하십시오.',
    phone: '연락 받을 핸드폰 번호',
    phone_pls: '연락 받을 핸드폰 번호를 입력하십시오.',
    teach_lan: '수업할 언어',
    teach_lan_pls: '언어를 선택하세요',
    delete: '지우다',
    meet_time: '면접 가능 시간',
    meet_time_pls: '면접 가능 시간을 입력하십시오.',
    resume: '이력 사항',
    resume_pls: '이력 사항을 입력하십시오.',

    sign_title: '아이디 만들기',
    password: '아이디 비밀번호',
    password_pls: '비밀번호를 입력하십시오.',
    sec_password: '비밀번호 확인',
    sec_password_pls: '비밀번호를 입력하십시오.',
    start_apply: '지원 시작'
  },
}
