<template lang="pug">
//- video-screen
.video-screen
  .container-fluid-sm
    video-player.video-player-box
    //- (
    //-   v-if="article.type > 0 && currentMedia.url",
    //-   :options="playerOptions",
    //-   customEventName="customstatechangedeventname",
    //-   :webkit-playsinline="true",
    //-   :playsinline="true",
    //-   :x5-playsinline="true"
    //- )
</template>
<script>
export default {
  name: 'video-screen',
  props: [
    'article',
    'currentMedia'
  ],
  components: {

  },
  watch: {
    currentMedia () {
      this.changeMedia()
    }
  },
  data () {
    return {
      playerOptions: {
        // 不給予控制功能
        controls: false,
        // 靜音
        muted: false,
        // 全版
        fill: true,
        // 語言
        language: 'zh-tw',
        // 看起來是可以加速的比例
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        // 影片檔案規格
        sources: []
      },
    };
  },
  computed: {

  },
  mounted () {

  },
  methods: {

    // player is ready
    // playerReadied (player) {
    //   if (!this.article) {
    //     return
    //   }

    //   this.videoPlayer = player
    //   console.log('[' + this.article.id + ']the player is readied: ', player)

    //   this.changeMedia()
    // },
    // // 更換播放器影音
    // changeMedia () {
    //   // 設置音視頻檔案
    //   if (this.currentMedia) {
    //     console.log('[' + this.article.id + ']---> 指定使用檔案: ', this.currentMedia)
    //     let mediaType = this.$root.getAttachmentMediaType(this.currentMedia.ext)
    //     this.videoPlayer.src({ type: mediaType, src: this.currentMedia.url })
    //   }

    //   // 按照類別處理畫面
    //   this.videoType = this.videoPlayer.currentType()
    //   console.log('[' + this.article.id + ']---> 目前播放的檔案類型: ', this.videoType)

    //   // 自動起始播放
    //   if (this.autoplay) {
    //     setTimeout(() => { this.startVideo() }, 300)
    //   }
    // },

  }
}
</script>

<style lang="sass" scoped>
.video-screen
</style>