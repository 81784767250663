<template lang="pug">
//- 團班課懷
.live-index.page-index
  Header
  //- TextBook
  Footer
</template>
<script>
// import $ from 'jquery'
import Header from '@/components/header'
import Footer from '@/components/footer'
// import TextBook from '@/components/textbook/textbook'

export default {
  name: 'timetableIndex',
  components: {
    Header,
    Footer,
    // TextBook
  },
  data () {
    return {
    };
  },
  async created () {
    this.$root.closeLoading()
  },
  mounted () {
  },
  computed: {
  },
  methods: {

  }
}
</script>
<style lang="sass">
</style>
