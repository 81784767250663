<template lang="pug">
//- B端 FOOTER
footer.home-footer
  //- .foot-top-hr
  //- 主要內容，分左右兩塊
  .container-fluid
    .row
      .col-1
      .col-10.row
        //- 左邊，LOGO 和社群
        .col-lg-3.col-md-3.col-sm-12.mb-10
          a
            img.footer-logo(src="@/assets/image/gv-oedu-g@3x.png")
          ul.footer-system-list
            li
              a.middle-center
                img(src="@/assets/image/appstore.png")
            li
              a.middle-center
                img(src="@/assets/image/googleplay.png")

        //- 右邊，一堆選單
        .col-lg-3.col-md-3.col-sm-4.col-xs-12.mb-10
          .footer-title 資源
          .footer-list-content.row.align-items-center.flex-nowrap(
            v-for="item in arrList",
            @click="$root.goPage(item.page, item.type)"
          )
            img.foot-list-img(src="@/assets/image/list-img.png")
            a.footer-list-item {{ item.name }}

        .col-lg-3.col-md-3.col-sm-4.col-xs-12.mb-10
          .footer-title 探索
          .footer-list-content.row.align-items-center.flex-nowrap(
            v-for="lan in searchList",
            @click="$root.goPage(lan.page, lan.type)"
          )
            img.foot-list-img(src="@/assets/image/list-img.png")
            a.footer-list-item {{ lan.name }}

        .col-lg-3.col-md-3.col-sm-4.col-xs-12.mb-10
          .footer-title 聯絡我們
          .footer-list-content(v-for="sup in supList")
            .footer-list-item {{ sup.name }}
      .col-1

  //- copy right
  .container-fluid.text-align-center
    .address
      span Copyright © 2021 地球村文教機構 版權所有．轉載必究
</template>
<script>
export default {
  name: 'homeFooter',
  components: {
  },
  data () {
    return {
      arrList: [
        // {
        //   name: '體驗課程',
        //   page: 'teachers',
        //   type: 1
        // },
        {
          name: '團班課表',
          page: 'timetable',
          type: 1
        },
        {
          name: '隱私權條款',
          page: 'privacy',
          type: 1
        },
        {
          name: '條款及細則',
          page: 'rule',
          type: 1
        },
        {
          name: '朗讀筆',
          page: 'readingPenQRcode',
          type: 1
        },
      ],
      searchList: [
        // {
        //   name: '教師應聘',
        //   page: 'apply',
        //   type: 1
        // },
        {
          name: '教師登入',
          page: 'https://edu1n1.com.tw/login/teacher',
          type: 2
        },
        // {
        //   name: '月刊解說',
        //   page: 'http://www.edu1on1.com/monthlyAudioList79',
        //   type: 2
        // },
        {
          name: '快訊PDF',
          page: 'http://www.edu1on1.com/dayEnglishNews',
          type: 2
        }
      ],
      supList: [
        {
          name: '週一至週五 10:00 ~ 18:00',
        },
        // {
        //   name: '週六、週日 10:00~17:00',
        // },
        {
          name: '客服專線 02-23708666',
        }
      ],
    };
  },
  methods: {

  }
}
</script>
