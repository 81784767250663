<template lang="pug">
//- 朗讀筆
.reading-pen-index.page-index
  Header
  .reading-pen-main
    PageTop.bg-gray(page="reading-pen")
    ReadingPenInfo
    ReadingPenDetail
  Footer
</template>
<script>
// import $ from 'jquery'
import Header from '@/components/header'
import Footer from '@/components/footer'
import ReadingPenInfo from '@/components/reading-pen/reading-pen-info'
import ReadingPenDetail from '@/components/reading-pen/reading-pen-detail'
import PageTop from '@/common/page-top'
export default {
  name: 'reading-penIndex',
  components: {
    Header,
    Footer,
    ReadingPenInfo,
    ReadingPenDetail,
    PageTop
  },
  data () {
    return {
    };
  },
  async created () {
    this.$root.closeLoading()

  },
  destroyed () {
    this.$root.nav_meta = ''
  },
  mounted () {
    this.$root.nav_meta = 'reading-pen'
  },
  computed: {
  },
  methods: {

  }
}
</script>
<style lang="sass">
</style>
