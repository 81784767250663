<template lang="pug">
//- 條款
.rule-index.page-index
  Header
  PageTop.bg-gray(page="rule")
  RuleMain
  Footer
</template>
<script>
// import $ from 'jquery'
import Header from '@/components/header'
import Footer from '@/components/footer'
import PageTop from '@/common/page-top'
import RuleMain from '@/components/rule/rule-main'

export default {
  name: 'ruleMain',
  components: {
    Header,
    Footer,
    PageTop,
    RuleMain
  },
  data () {
    return {
    };
  },
  async created () {
    this.$root.closeLoading()
  },
  mounted () {
  },
  computed: {
  },
  methods: {

  }
}
</script>
<style lang="sass">
</style>
