import Vue from 'vue'
import Router from 'vue-router'
import home from '@/pages/meta/home'
import teachers from '@/pages/meta/teachers'
import ReadingPen from '@/pages/meta/reading-pen'
import apply from '@/pages/meta/apply'
import timetable from '@/pages/meta/timetable'
import privacy from '@/pages/other/privacy'
import rule from '@/pages/other/rule'
import teacherLogin from '@/pages/other/teacher-login'
import studentLogin from '@/pages/other/student-login'
import studentPhoneBinding from '@/pages/other/phone-binding'
import live from '@/pages/other/live'
import book from '@/pages/other/book'
import notfound from '@/pages/404'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    //- 主畫面
    {
      path: '/',
      name: 'home',
      component: home,
    },
    {
      path: '/web/',
      name: 'home',
      component: home,
    },
    //- 外教師資
    {
      path: '/web/teachers',
      name: 'teachers',
      component: teachers,
    },
    //- 教師應聘
    {
      path: '/web/apply',
      name: 'apply',
      component: apply,
    },
    //- 團班課表
    {
      path: '/web/timetable',
      name: 'timetable',
      component: timetable,
    },
    //- 隱私權條款
    {
      path: '/web/privacy',
      name: 'privacy',
      component: privacy,
    },
    //- 條款及細則
    {
      path: '/web/rule',
      name: 'rule',
      component: rule,
    },
    //- 教師登入
    {
      path: '/web/teacher-login',
      name: 'teacher-login',
      component: teacherLogin,
    },
    //- 學生登入
    {
      path: '/web/student-login',
      name: 'student-login',
      component: studentLogin,
    },
    //- 學生登入
    {
      path: '/web/student-phone-binding',
      name: 'phone-binding',
      component: studentPhoneBinding,
    },
    //- 直播畫面
    {
      path: '/web/live',
      name: 'live',
      component: live,
    },
    //- 教材畫面
    {
      path: '/web/book',
      name: 'text-book',
      component: book,
    },
    //- 朗讀筆
    {
      path: "/material/readingPenQRcode",
      name: "readingPenQRcode",
      component: ReadingPen,
    },
    //- 404
    {
      path: '/web/notfound',
      name: 'notfound',
      component: notfound,
    },
    {
      path: '*',
      redirect: '/web/'
    },
  ]
})
